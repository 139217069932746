import { Injectable, OnDestroy, EventEmitter, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { CSInterface, CSEvent, SystemPath } from 'csinterface-ts/dist/csinterface-ts';
import { IllustratorItem, DocInfo, IllustratorFont } from '../_models/smartmapping';
import { FilesystemService } from './filesystem.service';
import { MoonDeskConfigService } from './moon-desk-config.service';
import { FrontendInfo,
         DocumentAccessProvider,
         DocumentContent,
         EventHubService,
         Severity} from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { environment } from 'src/environments/environment';

const EVENT_SELECTION_CHANGED = 'design.moondesk.csxs.events.selectionchanged';
const EVENT_DOCUMENT_CHANGED = 'design.moondesk.csxs.events.documentchanged';
const EVENT_DOCUMENT_SAVED = 'design.moondesk.csxs.events.documentsaved';
const EVENT_THEME_CHANGED = 'design.moondesk.csxs.events.themechanged';

export interface IllustratorAppInfo {
  appName: string;
  version: string;
  buildNumber: string;
  locale: string;
  userAdobeId: string;
  userGuid: string;
}

export interface KeyValuePair
{
    key: string;
    value: string;
}

export interface LinkedFile
{
  filePath: string;
  /** Measure in millimeters */
  width: number;
  /** Measure in millimeters */
  height: number;
}

export interface AiFonts
{
  diskFontName: string;
  illFontName: string;
}

interface OriginalDocPathMapping
{
  workingCopy: string;
  originalPath: string;
}

@Injectable()
export class IllustratorService implements OnDestroy, DocumentAccessProvider
{
  /**
   * Careful with that!
   * During 'sharing' (mass download, open and file creation) and rules execution, we suppress it
   * Only use in a try/catch/finally block!
   */
  suppressEvents: boolean = false;

  isDummy = false;
  private docPathsMapping: OriginalDocPathMapping[] = [];

  initialized: boolean = false;
  private resolve: any;
  initPromise: Promise<void> = new Promise((resolve) =>
  {
    this.resolve = resolve;
  });

  private csInterface: CSInterface;
  frontendInfo: FrontendInfo;

  private installedFonts: IllustratorFont[] = [];

  /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
  public ThemeChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * When the active document changed (can be undefined)
   * - a document was opened
   * - user switches between documents
   * - a document was closed
   * - a Document was 'Saved As'
   */
  public DocumentChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  /**
   * Some selection changes in the open Illustrator document. Can be used to
   * refresh contents, because there's no such information as 'Dirty' from Illustrator
   */
  public SelectionChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  /**
   * A document was Saved in Illustrator
   */
  public DocumentSavedEvent: EventEmitter<string> = new EventEmitter<string>();
  /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

  private useJsxLog: boolean;
  private evalScriptCounter: number = 0;

  // https://forums.adobe.com/message/9664567#9664567

  /*  USED TO IMPROVEMENT RUN RULE  */
  private lastDocumentChanged: boolean = true;
  private lastDocumentText: string;

  constructor(private http: HttpClient, private moonDeskConfig: MoonDeskConfigService,
    private zone: NgZone, private fileService: FilesystemService,
    private eventHubService: EventHubService)
  {
    console.log('IllustratorService startup');

    this.moonDeskConfig.getMoonDeskConfig().then(c => this.useJsxLog = c.useJsxLogging);
    this.csInterface = new CSInterface();
    // this.csInterface.setWindowTitle("MoonDesk (dev)");
    this.csInterface.addEventListener(EVENT_THEME_CHANGED, this.illustratorThemeChangedEvent);
    this.csInterface.addEventListener(EVENT_SELECTION_CHANGED, this.selectionChangedEvent);
    this.csInterface.addEventListener(EVENT_DOCUMENT_CHANGED, this.documentChangedEvent);
    this.csInterface.addEventListener(EVENT_DOCUMENT_SAVED, this.documentSavedEvent);

    this.csInterface.addEventListener('CicloJsxLog', this.jsxLog);
    this.initPromise = this.initializeJsxAndTempFolder();
  }

  ngOnDestroy(): void
  {
    this.csInterface.removeEventListener(EVENT_THEME_CHANGED, this.illustratorThemeChangedEvent);
    this.csInterface.removeEventListener(EVENT_SELECTION_CHANGED, this.selectionChangedEvent);
    this.csInterface.removeEventListener(EVENT_DOCUMENT_CHANGED, this.documentChangedEvent);
    this.csInterface.removeEventListener(EVENT_DOCUMENT_SAVED, this.documentSavedEvent);
    this.csInterface.removeEventListener('CicloJsxLog', this.jsxLog);
  }

  selectionChangedEvent = (csEvent: CSEvent) =>
  {
    console.log(`selectionChangedEvent: ${csEvent.data}, supressed: ${this.suppressEvents}`);
    if (!this.suppressEvents)
    {
      this.zone.run(() => this.SelectionChangedEvent.emit(csEvent.data));
      this.lastDocumentChanged = true;
    }
  }

  documentChangedEvent = (csEvent: CSEvent) =>
  {
    console.log(`documentChangedEvent: ${csEvent.data}, supressed: ${this.suppressEvents}`);
    if (!this.suppressEvents)
    {
      this.zone.run(() => this.DocumentChangedEvent.emit(csEvent.data));
    }
  }

  documentSavedEvent = (csEvent: CSEvent) =>
  {
    console.log(`documentSavedEvent: ${csEvent.data}, supressed: ${this.suppressEvents}`);
    if (!this.suppressEvents)
    {
      this.zone.run(() => this.DocumentSavedEvent.emit(csEvent.data));
    }
  }

  illustratorThemeChangedEvent = (csEvent: CSEvent) =>
  {
    console.log(`illustratorThemeChangedEvent: ${csEvent.data}`);
    this.zone.run(() =>
    {
      this.ThemeChangedEvent.emit(<any>csEvent.data);
    });
  }

  private async initializeJsxAndTempFolder()
  {
    try
    {
      // inject jsx files
      await this.injectJsxScript('assets/jsx/json2.js');
      await this.injectJsxScript('assets/jsx/shared.js');
      await this.injectJsxScript('assets/jsx/files.js');
      await this.injectJsxScript('assets/jsx/contents.js');
      await this.injectJsxScript('assets/jsx/preferences.js');
      // get appinfo
      const appInfoStr = await this.evalScriptInternal('getAppInfo()');
      console.log(`appInfoStr: ${appInfoStr}`);
      const appInfo: IllustratorAppInfo = JSON.parse(appInfoStr);
      const moonDeskConfig = await this.moonDeskConfig.getMoonDeskConfig();
      this.frontendInfo =
      {
        frontendName: 'ILST',
        frontendVersion: environment.clientVersion,
        containerVersion: moonDeskConfig.version,
        frontendLocale: appInfo.locale,
        adobeBuildNumber: appInfo.buildNumber,
        adobeUserGuid: appInfo.userGuid,
        adobeUserId: appInfo.userAdobeId,
        adobeVersion: appInfo.version,
        nodePlatform: this.fileService.node.getPlatform(),
        nodeHostname: this.fileService.node.getHostname()
      };
      await this.evalScriptInternal(`enableLogging('${moonDeskConfig.useJsxLogging}')`);
      const dark = await this.isDarkTheme();
      this.ThemeChangedEvent.emit(dark);
      this.initialized = true;
      this.resolve();

      // get user installed fonts
      this.installedFonts = await this.getIllustratorFonts();
    }
    catch (err)
    {
      console.log('Error initializing extend scripts');
      console.log(err);
    }
  }

  private async injectJsxScript(relativeFilePath: string): Promise<void>
  {
    console.log(`Injecting '${relativeFilePath}'...`);
    const content = await new Promise<string>((resolve, reject) =>
    {
      this.http.get(relativeFilePath, {responseType: 'text'})
      .subscribe(c =>
        {
          resolve(c);
        }, err =>
        {
          reject(err);
        });
    });
    const result = await this.evalScriptInternal(content, true);
    if (result.startsWith('Error'))
    {
      throw new Error(result);
    }
  }

  jsxLog = (csEvent: CSEvent) =>
  {
    if (this.useJsxLog)
    {
      console.log(`JsxLog: ${csEvent.data}`);
    }

    if (csEvent && csEvent.data)
    {
      const severity: Severity = Severity[csEvent.data.split(' ')[0]];
      if (severity)
      {
        this.eventHubService.extendedScriptLoggging.emit({message: csEvent.data, severity: severity });
      }
    }
  }

  async isDarkTheme(): Promise<boolean>
  {
    const result = await this.evalScriptInternal('isThemeDark()', false);
    return result === 'true';
  }

  alert(msg: string)
  {
    this.evalScript(`adobeAlert('${msg}')`);
  }

  openUrl(url: string): number
  {
    return this.csInterface.openURLInDefaultBrowser(url);
  }

  async hideStartScreen()
  {
    await this.evalScript('setAutoHideStartScreen()');
  }

  async showMissingFontDialog()
  {
    await this.evalScript('setShowMissingFontDialog()');
  }

  async isDocumentOpen(filePath: string): Promise<boolean>
  {
    try
    {
      filePath = this.prepareFilepath(filePath);
      const x = await this.evalScript(`isDocumentOpen('${filePath}')`);
      return x === 'true';
    }
    catch (err)
    {
      return false;
    }
  }

  async getOpenDocuments(): Promise<string[]>
  {
    try
    {
      const x = await this.evalScript('getOpenDocuments()');
      if (!x)
      {
        return undefined;
      }
      return JSON.parse(x);
    }
    catch (err)
    {
      if (err.message && err.message.startsWith('Error 1302: No such element'))
      {
        return undefined;
      }
      throw(err);
    }
  }

  async openDocument(filePath: string): Promise<void>
  {
    try
    {
      filePath = this.prepareFilepath(filePath);
      await this.evalScript(`openDocument('${filePath}')`);
    }
    catch (err)
    {
      throw new Error('Unexpected error opening the document');
    }
  }

  async closeDocument(filePath: string, save: boolean): Promise<void>
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`closeDocument('${filePath}', '${save}')`);
  }

  async saveDocument(filePath: string, compatibilityEnabled: boolean = true): Promise<void>
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`saveDocument('${filePath}','${compatibilityEnabled}')`);
  }

  async saveAsDocument(filePath: string, destinationPath: string, compatibilityEnabled: boolean = true): Promise<void>
  {
    filePath = this.prepareFilepath(filePath);
    destinationPath = destinationPath.replace(/\\/g, '/');
    await this.evalScript(`saveAsDocument('${filePath}', '${destinationPath}', '${compatibilityEnabled}')`);
  }

  async selectFolder(): Promise<string>
  {
    const result = await this.evalScript('selectFolder()');
    return result;
  }

  private async isIsolationMode(): Promise<boolean>
  {
    const result: string = await this.evalScript('getActiveLayer()');
    const regexp = /(\baislamiento\b)|(\bisolation\b)|(\bisolamento\b)/gi;
    return result.match(regexp) != null;
  }

  /**
   * Check and leave isolation mode if isIsolationMode == true
   */
  private async leaveIsolationMode()
  {
    const isIsolationMode = await this.isIsolationMode();
    if (!isIsolationMode)
    {
      return;
    }
    const exitIsolatioModeAiaPath = this.getAbsolutePathOfAssetFile('ExitIsolationModeActionFile.aia');
    await this.evalScript(`executeAdobeIllustratorAction('${exitIsolatioModeAiaPath}')`);
  }

  async executeMenuCommand(action: 'open' | 'new')
  {
    await this.evalScript(`executeMenuCommand('${action}')`);
  }

  async exportPng24(filePath: string, exportFilePath: string, big: boolean)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`exportPng24('${filePath}', '${exportFilePath}', '${big}')`);
  }

  async exportImage(filePath: string, exportFilePath: string, big: boolean)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`exportImage('${filePath}', '${exportFilePath}', '${big}')`);
  }

  async exportJPG(filePath: string, exportFilePath: string, lowRes: boolean)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`exportJPG('${filePath}', '${exportFilePath}', '${lowRes}')`);
  }

  async saveAsPdf(filePath: string, exportFilePath: string, lowRes?: boolean)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`saveAsPdf('${filePath}', '${exportFilePath}', '${lowRes}')`);
  }

  async saveAsPdf2(filePath: string, exportFilePath: string, lowRes?: boolean)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`saveAsPdf2('${filePath}', '${exportFilePath}', '${lowRes}')`);
  }

  async exportOutline(filePath: string, exportFilePath: string)
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    await this.evalScript(`exportOutline('${filePath}', '${exportFilePath}')`);
  }

  async getLinkedFiles(filePath: string): Promise<string[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getLinkedFiles('${filePath}')`);
    return JSON.parse(result);
  }


  /** This function will return the file path and the size
   *  width and height in millimeters of readed linked file*/
  async getVisibleLinkedFiles(filePath: string, artboardIndex?: number): Promise<LinkedFile[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getVisibleLinkedFiles('${filePath}', '${artboardIndex}')`);
    const linkedFiles: LinkedFile[] = JSON.parse(result);
    return linkedFiles;
  }

  async replaceLinks(filePath: string, replacements: KeyValuePair[])
  {
    /* #BUG 10047 -
    The values within the array must not be repeated (key, value)
    because the replaceLinks method will search and replace all the links that relate it.
    */
    const uniqKeyValuePairs = [];

    for (const kvp of replacements)
    {
      const exist = _.find(uniqKeyValuePairs , ukvp =>
                          {
                            return ((ukvp.key === kvp.key) && (ukvp.value === kvp.value));
                           });
      if (!exist)
      {
        uniqKeyValuePairs.push(kvp);
      }
    }

    filePath = this.prepareFilepath(filePath);
    let replacementJsons = JSON.stringify(uniqKeyValuePairs);

    // duplicate filepath backslashes
    let x = replacementJsons.split('\\');
    replacementJsons = x.join('\\\\');

    // add escape sign (backslash) to ' signs
    x = replacementJsons.split('\'');
    replacementJsons = x.join('\\\'');

    await this.evalScript(`replaceLinks('${filePath}', '${replacementJsons}')`);
  }

  async packageFile(filePath: string, exportFilePath: string): Promise<string[]>
  {
    filePath = this.prepareFilepath(filePath);
    exportFilePath = this.prepareFilepath(exportFilePath);
    const result = await this.evalScript(`packageFile('${filePath}', '${exportFilePath}')`);
    return JSON.parse(result);
  }

  async selectItems(filePath: string, code: string): Promise<boolean>
  {
    filePath = this.prepareFilepath(filePath);
    try
    {
      const result = await this.evalScript(`selectItems('${filePath}', '${code}')`);
      return result === 'true';
    }
    catch (err)
    {
      return false;
    }
  }

  async unselectItems(filePath: string)
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`unselectItems('${filePath}')`);
  }

  /**
   * Assigns the given code to the current selection.
   * Only works if the given file is the currently active document.
   * Only works if there's only one item selected in the document!
   * @returns Error string in case something went wrong
   */
  async recodeSelection(filePath: string, code: string, type: 'TextFrame'): Promise<string>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`recodeSelection('${filePath}', '${code}', '${type}')`);
    if (result === 'undefined')
    {
      return undefined;
    }
    return result;
  }

  async bringSelectionIntoView(filePath: string): Promise<void>
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`bringSelectionIntoView('${filePath}')`);
  }

  async createTextField(filePath: string, code: string, text: string): Promise<boolean>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`createTextField('${filePath}', '${code}', '${text}')`);
    return result === 'true';
  }

  async getTextValue(filePath: string, code: string): Promise<string>
  {
    filePath = this.prepareFilepath(filePath);
    return await this.evalScript(`getTextValue('${filePath}', '${code}')`);
  }

  async setTextValue(filePath: string, code: string, text: string): Promise<boolean>
  {
    filePath = this.prepareFilepath(filePath);
    console.log(`Assigning '${text}' to field '${code}' in file ${filePath}...`);
    const result = await this.evalScript(`setTextValue('${filePath}', '${code}', '${text}')`);
    return result === 'true';
  }

  async findAndReplace(
    filePath: string,
    findText: string,
    replaceText: string,
    wholeWord: boolean,
    caseSensitive: boolean = true): Promise<number>
  {
    if (!findText)
    {
      throw Error('Empty find value not allowed');
    }
    filePath = this.prepareFilepath(filePath);
    findText = this.escapeChars(findText);
    replaceText = this.escapeChars(replaceText);
    const result = await this.evalScript(`findReplace('${filePath}', '${findText}', '${replaceText}', '${wholeWord}', '${false}', '${false}', '${caseSensitive}')`);
    return +result;
  }

  async linkContentToCurrentDocument(documentContent: DocumentContent , type: 'BOLD' | 'NORMAL' ,
                    illustratorFontNormal: IllustratorFont, illustratorFontBold: IllustratorFont): Promise<string>
  {
    let text = this.escapeChars(documentContent.content);
    text = text.replace(/(\n)/gm, '\\n');
    if (!text)
    {
      return;
    }
    const result = await this.evalScript(`linkContentToCurrentDocument('${text}', '${type}',
                                          '${illustratorFontNormal.name}' , '${illustratorFontBold.name}')`);
    if (result.startsWith('Error'))
    {
      throw new Error(result);
    }
    if (result !== 'undefined')
    {
      return result;
    }
    return undefined;
  }

  async find(
    filePath: string,
    findText: string,
    wholeWord: boolean,
    ignoreLineBreaks?: boolean,
    caseSensitive: boolean = true,
    artboardIndex?: number): Promise<number>
  {
    filePath = this.prepareFilepath(filePath);
    findText = this.escapeChars(findText);
    const result = await this.evalScript(`find('${filePath}', '${findText}', '${wholeWord}', '${ignoreLineBreaks}', '${caseSensitive}', '${artboardIndex}')`);
    return +result;
  }

  async findLinkedImage(
    filePath: string,
    linkId: string,
    artboardIndex?: number): Promise<number>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`findLinkedImage('${filePath}', '${linkId}', '${artboardIndex}')`);
    return +result;
  }


  /**
   * artboardIndex = null for all artboards
   */
  async findDoubleSpaces(filePath: string, artboardIndex?: number): Promise<number>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`findDoubleSpaces('${filePath}', '${artboardIndex}')`);
    return +result;
  }

  async deselectDocumentElements(filePath: string)
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`deselectDocumentElements('${filePath}')`);
  }

  async deselectAll()
  {
    await this.evalScript('deselectAll()');
  }

  async getCurrentSelection(filePath: string)
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getCurrentDocumentSelection('${filePath}')`);
    return result;
  }

  async getCurrentTextSelection()
  {
    const result = await this.evalScript(`getCurrentTextSelection()`);
    if (result.startsWith('Error'))
    {
      throw new Error(result);
    }
    return result;
  }

  /**
   * Get the height of the smallest character in the string
   */
  async getTextSize(filePath: string, text: string, wholeWord: boolean = true, biggestSize: boolean = false): Promise<number>
  {
    const findText = this.escapeChars(text);
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getTextSize('${filePath}','${findText}','${wholeWord}', '${biggestSize}')`);
    return +result;
  }

  escapeChars(text: string): string
  {
    console.log(`input: ${text}`);
    text = text.replace(/(?<!\\)'/g, '\\\'');
    console.log(`output: ${text}`);
    return text;
  }

  /**
   * Get a list of all items of the given Illustrator document
   */
  async getItems(filePath: string): Promise<IllustratorItem[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getItems('${filePath}')`);
    const items: IllustratorItem[] = JSON.parse(result);
    return items;
  }

  /**
   * Get a list of all visible items of the given Illustrator document (even if they are in a locked layer)
   */
  async getVisibleItems(filePath: string, artboardIndex?: number): Promise<IllustratorItem[]>
  {
    await this.leaveIsolationMode();
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getVisibleItems('${filePath}', '${artboardIndex}')`);
    const items: IllustratorItem[] = JSON.parse(result);
    return items;
  }

  async getAllTextWithBoldReferences(filePath: string): Promise<string>
  {
    if (!this.lastDocumentChanged)
    {
      return this.lastDocumentText;
    }
    else
    {
      await this.deselectAll();
      filePath = this.prepareFilepath(filePath);
      const result = await this.evalScript(`getAllTextWithBoldReferences('${filePath}')`);
      this.lastDocumentChanged = false;
      this.lastDocumentText = result;
      return result;
    }
  }

  async getAllTextPlain(filePath: string): Promise<string>
  {
    if (!this.lastDocumentChanged)
    {
      return this.lastDocumentText;
    }
    else
    {
      await this.deselectAll();
      filePath = this.prepareFilepath(filePath);
      const result = await this.evalScript(`getAllTextPlain('${filePath}')`);
      this.lastDocumentChanged = false;
      this.lastDocumentText = result;
      return result;
    }
  }


  /**
   * Get a list of all items of the given Illustrator document, with a tag number.
   * This tag number can be reused later to identify the same element again.
   * ATTENTION: This function manipulates the document, and naturally changes its
   * 'dirty' status as well as the hashsum.
   */
  async getItemsWithTagNumber(filePath: string): Promise<IllustratorItem[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getItemsWithTagNumber('${filePath}')`);
    const items: IllustratorItem[] = JSON.parse(result);
    return items;
  }

  async assignCodesByTagNumbers(filePath: string, items: IllustratorItem[]): Promise<void>
  {
    filePath = this.prepareFilepath(filePath);
    const stringedItems = JSON.stringify(items);
    const result = await this.evalScript(`assignCodesByTagNumbers('${filePath}', '${stringedItems}')`);
    if (result === 'false')
    {
      throw Error('Error assigning codes');
    }
  }

  async setExtDocInfo(filePath: string, docId: string, version: number, minorVersion: number)
  {
    await this.leaveIsolationMode();
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`setExtDocInfo('${filePath}', '${docId}', '${version}', '${minorVersion}')`);
  }

  async deleteExtDocInfo(filePath: string)
  {
    filePath = this.prepareFilepath(filePath);
    await this.evalScript(`deleteExtDocInfo('${filePath}')`);
  }

  /**
   * gets the result[0] documentId and the result[1] versionNumber, if exists
   * if not it's two empty strings
   */
  async getExtDocInfo(filePath: string): Promise<string[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getExtDocInfo('${filePath}')`);
    return JSON.parse(result);
  }

  async getExtOpenDocInfos(): Promise<DocInfo[]>
  {
    await this.leaveIsolationMode();
    const result = await this.evalScript(`getExtOpenDocInfos()`);
    return JSON.parse(result);
  }

  async getLocalLinks(filePath: string): Promise<string[]>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`getLocalLinks('${filePath}')`);
    return JSON.parse(result);
  }

  /*Return an array of links to document linked to ai document*/
  async imageCapture(sourcePath: string, targetPath: string, resolution: number): Promise<void>
  {
    sourcePath = this.prepareFilepath(sourcePath);
    targetPath = this.prepareFilepath(targetPath);
    await this.evalScript(`imageCapture('${sourcePath}', '${targetPath}', '${resolution}')`);
  }

  /*Check all document TextFrames finding text overflow and return number of TextFrames overflowed */
  async checkTextOverflow(filePath: string, artboardIndex?: number): Promise<number>
  {
    filePath = this.prepareFilepath(filePath);
    const result = await this.evalScript(`checkTextOverflow('${filePath}', '${artboardIndex}')`);
    return JSON.parse(result);
  }

  async placeItem(filePath: string, embed: boolean = false)
  {
    if (!filePath)
    {
      throw new Error('Invalid filepath');
    }
    try
    {
      filePath = this.prepareFilepath(filePath);
      await this.evalScript(`placeItem('${filePath}', '${embed}')`);
    }
    catch (err)
    {
      if (err.message && err.message.startsWith('Error 8705'))
      {
        throw new Error('Current layer is either hidden or locked');
      }
      throw (err);
    }
  }

  async getFonts(documentPath: string): Promise<AiFonts[]>
  {
    const result: AiFonts[] = [];
    const tempDocBuffer = await this.fileService.readFileAsync(documentPath, {encoding: 'UTF-8'});
    const tempDoc = tempDocBuffer.toString();
    if (tempDoc.startsWith('Error'))
    {
      throw new Error(tempDoc);
    }
    const startPosition = tempDoc.indexOf('xmpTPg:Fonts');
    const endPosition = tempDoc.indexOf('/xmpTPg:Fonts', startPosition + 5);

    let xmlFonts = tempDoc.substring(startPosition + 35, endPosition - 21);

    // eslint-disable-next-line max-len
    const xmlStart = '<rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"> <rdf:Description rdf:about="" xmlns:stFnt="http://ns.adobe.com/xap/1.0/sType/Font#">';
    const xmlEnd = '</rdf:Description> </rdf:RDF>';

    xmlFonts = xmlStart + xmlFonts + xmlEnd;

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlFonts, 'application/xml');

    const fontsList = Array.from(xmlDoc.getElementsByTagName('stFnt:fontFileName'));
    const fontsName = Array.from(xmlDoc.getElementsByTagName('stFnt:fontName'));

    for (let i = 0; i < fontsList.length; i++)
    {
      let diskFontName: string = '';
      if (fontsList[i].childNodes[0])
      {
        diskFontName = fontsList[i].childNodes[0].nodeValue; // .split(';');
      }

      let illFontName: string = '';
      if (fontsName[i].childNodes[0])
      {
        illFontName = fontsName[i].childNodes[0].nodeValue; // .split(';');
      }

      result.push({diskFontName, illFontName});
    }
    return _.uniq(result);
  }

  async getIllustratorFonts(): Promise<IllustratorFont[]>
  {
    const result = await this.evalScript(`getIllustratorFonts()`);
    if (result.startsWith('Error'))
    {
      throw Error('Error reading installed fonts');
    }
    else
    {
      const fonts: IllustratorFont [] = JSON.parse(result);
      return fonts;
    }
  }

  // FIX contents.js.getCurrentDocFonts() before using.
  // Sometimes it breaks with the following error: "Error 1200: an Illustrator error occurred: 1430996551 ('UKFG')"
  // Occurs when trying to read char fontname.
  // async getCurrentDocFonts(): Promise<IllustratorFont[]>
  // {
  //   const result = await this.evalScript(`getCurrentDocFonts()`);
  //   if (result.startsWith('Error'))
  //   {
  //     throw Error('Error reading current doc fonts');
  //   }
  //   else
  //   {
  //     const fonts: IllustratorFont [] = JSON.parse(result);
  //     return fonts;
  //   }
  // }

  async getDocMissingFonts(docPath: string): Promise<string[]>
  {
    const userFonts: string[] = this.installedFonts.map(illFonts => illFonts.name);
    // const currentDocFonts: IllustratorFont[] = await this.getCurrentDocFonts();
    const currentDocFonts = await this.getFonts(docPath);
    const currentDocFontsNames = currentDocFonts.map(currentDocFont => currentDocFont.illFontName);

    let missingFontsNames: string[] = [];
    missingFontsNames = _.filter(currentDocFontsNames, docFont => !_.any(userFonts, userFont => userFont === docFont));

    return missingFontsNames;
  }

  async removeCurrentDocumentSelectedText()
  {
    try
    {
      await this.evalScript('removeCurrentDocumentSelectedText()');
    }
    catch (err)
    {
      throw (err);
    }
  }

  // HELPERS
  async evalScript(script: string, suppressLog: boolean = false): Promise<string>
  {
    if (!this.initialized)
    {
      console.log(`waiting for init...`);
      await this.initPromise;
      console.log(`...init done`);
    }
    const result = await this.evalScriptInternal(script, suppressLog);
    if (result && result.startsWith('Error'))
    {
      this.eventHubService.extendedScriptLoggging.emit({message: result, severity: Severity.Error });
      throw new Error(result);
    }
    return result;
  }

  private evalScriptInternal = (script: string, suppressLog: boolean = false) =>
  {
    ++this.evalScriptCounter;
    return new Promise<string>(async (resolve) =>
    {
      const counter = this.evalScriptCounter;
      const sLog: boolean = suppressLog;
      if (!sLog && this.useJsxLog)
      {
        console.log(`${counter}: evalScript(${script})...`);
      }
      const start = Date.now();
      this.csInterface.evalScript(script, res =>
        {
        if (!sLog && this.useJsxLog)
        {
          const time = Date.now() - start;
          console.log(`${counter} | ${time}ms: evalScript result: ${res}`);
        }
        resolve(res);
      });
    });
  }

  private getAbsolutePathOfAssetFile(filename: string): string
  {
    const extensionRoot = this.csInterface.getSystemPath(SystemPath.EXTENSION);
    const distFolder = this.fileService.join(extensionRoot, 'dist');
    const assetsFolder = this.fileService.join(distFolder, 'assets');
    const filepath = this.fileService.join(assetsFolder, filename);
    const cleanPath = this.prepareFilepath(filepath);
    return cleanPath;
  }

  /**
   * ExtendScripts get called by a string containing a script. So if that script contains
   * string values (file paths in this case), which in turn contain reserved characters
   * (like backslashes or apostrophes), we have to escape them again, so the original data
   * (path) gets interpreted in the ExtendScript
   */
  prepareFilepath(path: string): string
  {
    if (!path || path === '')
    {
      console.log('invalid path');
      return;
    }
    // backslashes (like in windows) must be duplicated,
    // because they're escape characters
    if (path.indexOf('\\') >= 0)
    {
      const x = path.split('\\');
      path = x.join('\\\\');
    }
    // apostrophes inside a string need an escape character,
    // to make sure the string arrives entirely to extendscript
    if (path.indexOf(`\\'`) >= 0)
    {
      const x = path.split(`\\'`);
      path = x.join(`\\\'`);
    } {
    return path;
    }
  }

  /**
   * Used when importing existing documents, necessary to know where the original document is on the user's PC.
   * @param workingCopy The path of document that has illustrator open. It changes when we save it in the MoonDesk cache.
   * @param originalPath The path of the user's original document.
   */
  addDocPathMapping(workingCopy: string, originalPath: string)
  {
    const existing = this.docPathsMapping.find(p => p.workingCopy === originalPath);
    if (existing)
    {
      existing.workingCopy = workingCopy;
    }
    else
    {
      this.docPathsMapping.push({workingCopy: workingCopy, originalPath: originalPath});
    }

    // It is not necessary, but just in case. This keeps growing until the user closes illustrator. 
    // Let's keep this list to a maximum of 100 removing the oldest element.
    // (it is very unlikely that a user will have 100+ documents open at the same time).
    if (this.docPathsMapping.length > 100)
    {
      this.docPathsMapping.shift();
    }
  }

  updateDocPathMappingWorkingCopy(oldWorkingCopy: string, newWorkingCopy: string)
  {
    const existing = this.docPathsMapping.find(p => p.workingCopy === oldWorkingCopy);
    if (existing)
    {
      existing.workingCopy = newWorkingCopy;
    }
  }

  getDocOriginalPath(workingCopy: string): string | null
  {
    console.log(this.docPathsMapping);
    const existing = this.docPathsMapping.find(p => p.workingCopy === workingCopy);
    if (existing)
    {
      return existing.originalPath;
    }
    return null;
  }
}
