<div class="class-selector">

  <form>

    <div class="selector-header">
      <div class="header-search" *ngIf="!hideSearch" [libTooltip]="searchInfo">
        <input
          id="searchInput"
          class="search-input"
          type="search"
          placeholder="Search in documents..."
          [formControl]="search"
          [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @if (isNumber(search.value))
            {
              <mat-option [value]="search.value" (onSelectionChange)="emit(true, true)">
                Number: {{search.value}}
              </mat-option>
            }
            @if (search.value)
            {
              <mat-option [value]="search.value" (onSelectionChange)="emit(false, true)">
                Contain: {{search.value}}
              </mat-option>
            }
        </mat-autocomplete>
      </div>

      <ng-template #searchInfo>
        <div class="search-tooltip">
          <h4>SEARCH BY:</h4>
          <ul>
            <li>Separate words. E.g: malbec roble</li>
            <li>Whole word or phrase. E.g: "gran malbec"</li>
            <li>Word not included. E.g: -"malbec"</li>
          </ul>
        </div>
      </ng-template>

      <!--Documents-->
      <div class="header-documents">
        <div class="versionstatus-selector" *ngIf="showStatusFilter">
          <label class="selector-name selector-name-ext">Select Document Status</label>
          <div class="selector-options">
            <mat-checkbox id="draft" class="option-checkbox-ext" name='draft' [(ngModel)]="draft" (change)="emit($event)">Draft</mat-checkbox>
            <mat-checkbox id="preapproved" *ngIf="isPreapproveEnabled" class="option-checkbox-ext" name='preapproved' [(ngModel)]="preapproved" (change)="emit($event)">Preapproved</mat-checkbox>
            <mat-checkbox id="approved" class="option-checkbox-ext" name='approved' [(ngModel)]="approved" (change)="emit($event)">Approved</mat-checkbox>
          </div>
        </div>
        <mat-form-field class="doctype-form" *ngIf="documentTypes && documentTypes.length > 3">
          <mat-select #docTypeSelector placeholder="Select Document Type" [formControl]="docTypes" [multiple]="multiDocTypeSelection" [disabled]="disableDocumentType || _disabled" [attr.tabindex]="1" >
            <button *ngIf="multiDocTypeSelection" mat-icon-button class="close" (click)="docTypeSelector.close()"><mat-icon>close</mat-icon></button>
            <div *ngIf="showAllText">
              <mat-checkbox
                class="firstoption all-check"
                [indeterminate]="docTypeShowAllCheckbox() === 2"
                [checked]="docTypeShowAllCheckbox() === 1"
                (change)="docTypeCheckAllChanged($event.checked)">
                All Document Types
              </mat-checkbox>
              <mat-divider></mat-divider>
            </div>
            <mat-option  [ngClass]="{'firstoption': 'showAllText' }" *ngFor="let docType of documentTypes" [value]="docType">{{docType.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="doctype-selector">
          <div class="doctypes" *ngIf="documentTypes && documentTypes.length <= 3 && documentTypes.length > 0">
            <label class="selector-name selector-name-ext">Select Document Type</label>
            <div class="selector-options">
              <ng-container  *ngIf="multiDocTypeSelection">
                <mat-checkbox e2e="docTypesCheckBox" class="option-checkbox option-checkbox-ext" [disabled]="disableDocumentType" *ngFor="let docType of documentTypes" [checked]="isChecked(docType)" (change)="docTypeSelectMultiple($event.checked, docType)" [value]="docType" [id]="'docType'+docType.name">{{docType.name}}</mat-checkbox>
              </ng-container>

              <mat-radio-group  class="option-radio" [disabled]="disableDocumentType" [formControl]="docTypes" *ngIf="!multiDocTypeSelection">
                <mat-radio-button *ngFor="let docType of documentTypes"  [name]="'docTypeRadio'+docType.name"  [value]="docType"> 
                  {{docType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="actions">
            <mat-icon mat-button class="notranslate" *ngIf="showResetButton" 
                      matTooltip="Reset Filters" matTooltipPosition="above"
                      (click)="resetFilter(true, true)">block</mat-icon>
            <mat-icon mat-button class="notranslate" *ngIf="showAutoAssignButton" 
                      matTooltip="Auto Classification" matTooltipPosition="above" 
                      (click)="autoAssignClicked.emit()">autorenew</mat-icon>
            <mat-icon mat-button class="notranslate" *ngIf="showAutoClassification && selectedClasses?.length > 0" 
                      matTooltip="Content based classification" matTooltipPosition="above" 
                      (click)="classifyWithContent()">auto_awesome</mat-icon>
          </div>
          <div class="no-doctype" *ngIf="documentTypes && documentTypes.length===0">
            <label class="selector-name">Select Document Type</label>
            <span>Please create some document types first</span>
          </div>
        </div>
        <div class="refresh-button">
          <span *ngIf="!hideHardResetButton && !refreshingCompany" (click)="hardFilterReset()" [matTooltip]="'lid.ext.pages.class-selector.reloadClassifiers' | translate">
            <mat-icon>refresh</mat-icon>
          </span>
          <mat-progress-spinner *ngIf="refreshingCompany" class="spinner" diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
      </div>
    </div>

    <!--Classes-->
    <div class="selector-classes" *ngIf="!refreshingCompany">
      <div class="classes-filter" *ngFor="let class of selectedClasses" [id]="'class'+class.name" [formGroup]="filters">



        <mat-form-field class="filter-dropdown" color="accent" [ngClass]="{'is-reduced' : isUser()}">

          <mat-select
            class="dropdown-menu"
            panelClass="class-selector-panel"
            [disabled]="_disabled"
            e2e="classSelect"
            (openedChange)="cdkVirtualScroll?.scrollToIndex(0);$event ? class.showCrudIcons = true : class.showCrudIcons = false; resetClassFilteredValues(class); searchInput.focus();"
            #classSelectorFilter
            [placeholder]="getClassPlaceholder(class)"
            [formControlName]="class.id"
            [multiple]="isMultipleSelectionForClass(class)"
            [attr.tabindex]="1"
            [required]="class.requiredValue && controlRequiredValues">

            <div class="class-filter-header">
              <div class="close-header">
                  <span class="close-icon" (click)="classSelectorFilter.close()">
                      <mat-icon class="notranslate">cancel</mat-icon>
                  </span>
              </div>
              <div class="text-input">
                  <input
                  class="class-search-input"
                  #searchInput
                  disableOptionCentering
                  (keyup)="applyFilter($event.target.value, class)"
                  (keydown)="$event.stopPropagation()"
                  [formControl]="classSearchCtrl"
                  [placeholder]="'Find ' + class.name + '...'">
                  <div class="actions" matSuffix>
                      <span class="action-icon" (click)="classSearchCtrl.setValue('')">
                          <mat-icon class="notranslate">close</mat-icon>
                      </span>
                      <span class="action-icon" *ngIf="showAddClassValueButton()" (click)="addValue(class, filterValue)" [matTooltip]="'Add value'">
                          <mat-icon class="notranslate">add_circle_outline</mat-icon>
                      </span>
                  </div>

              </div>
            </div>

            <div class="class-filter-body">


                <!-- DO NOT DELETED, WITHOUT THIS, MAT SELECT AND CDK VIRTUAL DOESNT SHOW REAL SELECTED VALUES ON PLACEHOLDER
                  SO THIS FIX IS TO KEEP CURRENT VALUES WHEN SCROLLING VIRTUAL -->
                <ng-container *ngFor="let cv of getCheckFilterSelected(class)">
                  <mat-option class="option-trigger" *ngIf="!class.showCrudIcons || !isOptionInRange(class,cv,cdkVirtualScroll)" [value]="cv">
                    <div class="name">{{cv.name}} <span *ngIf="cv.code">- {{cv.code}}</span></div>
                  </mat-option>
                </ng-container>

                <cdk-virtual-scroll-viewport #cdkVirtualScroll itemSize="32" class="virtual-scroll-filter" minBufferPx="250" maxBufferPx="400">

                <div class="menu-firstoptions" *ngIf="showAllText && class.filteredValues.length === class.reducedClassValues.length">
                  <mat-checkbox
                    class="all-check"
                    [indeterminate]="clsShowAllCheckbox(class) === 2"
                    [checked]="clsShowAllCheckbox(class) === 1"
                    (change)="clsCheckAllChanged($event.checked, class)">
                    All {{class.name}}
                  </mat-checkbox>
                  <mat-option
                    (click)="clsBlankChanged(class)"
                    [value]="'blank'">
                    Blank
                  </mat-option>
                  <mat-divider></mat-divider>
                </div>

                <mat-option class="option" *ngIf="class.singleValue && !ignoreSingleValueConfig" [value]="{}">
                  <span class="text" translate>None</span>
                </mat-option>


                <mat-option *cdkVirtualFor="let options of checkFilter(class)" e2e="optionsFilter" class="option"  [ngClass]="{'firstoption': 'showAllText' }" [value]="options">
                  <div e2e="optionsFilterValue" [id]="'classifier'+options.name" (click)="classSelectorFilter.close()">{{options.name}} <span *ngIf="options.code">- {{options.code}}</span></div>
                  <div class="actions" *ngIf="showAddClassValueButton() && class.showCrudIcons" (click)="$event.stopPropagation()">
                    <mat-icon id="edit-value-icon" (click)="editValue(class, options)">edit</mat-icon>
                    <mat-icon id="delete-value-icon" (click)="deleteValue(class, options)">delete</mat-icon>
                  </div>
                </mat-option>

                <div class="no-results" *ngIf="checkFilter(class).length === 0">
                  <span>{{class.name}} not found</span>
                </div>
              </cdk-virtual-scroll-viewport>
            </div>
          </mat-select>
        </mat-form-field>

        <div class="filter-icons">
          <mat-icon *ngIf="showAddClassValueButton()" id="add-value-icon" (click)="addValue(class)">add_circle_outline</mat-icon>
        </div>
      </div>
    </div>

  </form>

</div>