import { Injectable } from '@angular/core';
import { GlobalTradeItemDto } from '../_modelsDTO/barcodeQR/GlobalTradeItemDto';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BarcodeQRService
{

  constructor(private authService: AuthService)
  {}

  async getGlobalTradeItem(gtin: string): Promise<GlobalTradeItemDto>
  {
    let result: GlobalTradeItemDto;
    try
    {
      result = await this.authService.authGet<GlobalTradeItemDto>(
        `/api/v2/barcodeQR/GetGlobalTradeItem?gtin=${gtin}`
      );
    }
    catch (error)
    {
      if (error?.error !== 'GlobalTradeItemNotFound')
      {
        throw error; // GS1 connection error
        console.error(error);
      }
    }
    return result;
  }
}
