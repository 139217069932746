import { MoonTaskType } from './MoonTask';

export interface TaskFilter
{
  companyId: string;

  page: number;
  searchText?: string;
  searchTextIn?: TaskFilterSearchTextIn;
  taskTypes?: MoonTaskType[];
  filterBy?: 'MyTasks' | 'MyPendings' | 'MyPendingReviews' | 'Archived'; // undefined for all
  archived?: boolean;
  state?: 'All' | 'Active' | 'Expired';
  /**
   * 'Done' | 'InReview' | 'InWork'
   */
  statuses?: string[];
  creatorUserIds?: string[];
  ownersUserId?: string[];
  assignedUserId?: string[];
  reviewerUserIds?: string[];
  reviewerExternalIds?: string[];
  anyRoleUserId?: string;
  anyRoleReviewerId?: string;
  /**
   * taskProjectId OR tasksWithoutProjects, if both tasksWithoutProjects have priority
   */
  taskProjectId?: string;
  tasksWithoutProjects?: boolean;
  taskNumber?: number;
  documentNumber?: number;
  minDate?: Date;
  maxDate?: Date;
  minExpirationDate?: Date;
  maxExpirationDate?: Date;
  minLastUpdateDate?: Date;
  maxLastUpdateDate?: Date;

  orderBy?:
  'MyPendings' |
  'Timestamp' |
  'TaskNumber' |
  'Name' |
  'CreatedBy'|
  'Owner' |
  'Assigned' |
  'Priority' |
  'TaskStatus' |
  'LastUpdate' |
  'ExpirationDate';

  reverseOrder?: boolean;
}

export enum TaskFilterSearchTextIn
{
  NameOrDescription,
  Users,
  DocumentNames,
  All
}
