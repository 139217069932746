import { Injectable } from '@angular/core';
import { LoggingService, Severity } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/services/logging.service';
import { EventHubService } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/services/event-hub.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  showSupportButton: boolean;

  constructor(private loggingService: LoggingService,
                private snackBar: MatSnackBar,
                private eventHubService: EventHubService)
              { }

  /** For giving the user positive feedback about any action (e.g. Upload complete, or text replaced). */
  async notifyMessage( msg: string)
  {
    this.loggingService.trackTrace(msg, Severity.Information);
    const ssb = this.snackBar.open(msg, 'OK', {duration: 4000, panelClass: 'snack'});
    ssb.onAction().subscribe(() =>
    {
      ssb.containerInstance.exit();
    });
  }

  /** For giving the user feedback about any error that occured. Leads him to support popup if he wants.
   * The message is supposed to be shown to the user (snackBar), the error (object or string) is being
   * passed to the support popup, if the user chooses to go there.
  */

  async notifyError(msg: string, error?: any)
  {
    if (error)
    {
      if (error.error)
      {
        msg = error.error;
      }
      this.loggingService.logException(error);
    }
    else
    {
      this.loggingService.trackTrace(msg, Severity.Error);
    }
    this.eventHubService.silentSupportMessage.emit({message: msg, error: error});

    const ssb = this.snackBar.open(msg, 'OK', {panelClass: 'snack'});
    ssb.onAction().subscribe(() =>
    {
      ssb.containerInstance.exit();
    });
  }
}
