import { Injectable, Inject, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { AuthService, Progress } from './auth.service';

import { MoonTask, MoonTaskAttachment, TaskClassValue } from '../_models/tasks/MoonTask';
import { User } from '../_models/user/User';
import { Company } from '../_models/configuration/Company';
import { RejectReason, SubTaskMessage, SubTaskMessageFilter, SubTaskMessageResponse } from '../_models/tasks/SubTaskMessage';
import { DocumentService } from './document.service';
import { SubTask } from '../_models/tasks/SubTask';
import { Reviewer, ReviewStatus, ReviewerAssignment } from '../_models/tasks/Reviewer';
import { TaskFilter } from '../_models/tasks/TaskFilter';
import { TaskReportLogCompany } from '../_models/tasks/TaskReportLogs';
import { TasksResponse } from '../_models/tasks/TasksResponse';
import { Identity } from '../_models/user/Identity';
import { TaskQuery, SubTaskQuery } from '../_models/tasks/TaskQueries';
import { HttpParams } from '@angular/common/http';
import {
  RuleConfiguration,
  ContentTextRuleConfiguration,
  RuleIDs,
  ContentLibraryRuleConfiguration
} from '../_models/rules/RuleConfiguration';
import { ContentGroup, DocumentContent } from '../_models/tasks/DocumentContent';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { MoonDeskDocument } from '../_models/document/Document';
import { LoggingService } from './logging.service';
import {
  DocumentTaskHistoryResult,
  DocumentTaskHistoryRequest,
  DocumentVersion,
  TaskHistory,
  ReviewersGroup,
  ProjectWithTaskCountDto,
  TaskWithNameDto,
  TaskForReviewDto,
  TaskForExtensionDto,
  ShareTaskDto
} from '../public_api';
import { TaskProject } from '../_models/tasks/TaskProject';
import { TextsFormattingService } from './texts-formatting.service';
import { TaskMessage, TaskMessageFilter, TaskMessageResponse } from '../_models/tasks/TaskMessage';
import { ReviewerWithPendingTasksDTO } from '../_modelsDTO/tasks/ReviewerWithPendingTasksDTO';
import { TaskSearchDto } from '../_modelsDTO/tasks/TaskSearchDto';
import { PagedResponse } from '../_models/api/PagedResponse';
import { UserWithTaskDto } from '../_modelsDTO/tasks/UserWithTaskDto';

export interface TaskEvent
{
  event: 'TaskCreated'|'TaskUpdated'|'SubTaskChanged'|'SubTaskAdded';
  task: MoonTask;
  subTask: SubTask;
}

export interface TaskAssignedPost
{
taskId: string;
assignedId: string;
}

export interface SubTaskName
{
  subTaskId: string;
  name: string;
}

export interface TaskActionPost
{
    subTaskId: string;
    documentVersion: number;
    action: 'Approve' | 'Reject' | 'Reopen';
    newDocumentStatus: string;
    subTaskMessage: SubTaskMessage;
    /**
     * only if action === 'Reject'
     */
    rejectReason?: RejectReason;
}

interface AddAnonymousReviewerRequest
{
  taskId: string;
}

interface UpdateShareTaskExpirationDateRequest
{
  companyId: string;
  taskId: string;
  expirationDate: Date;
}

interface DownloadTasksReportPost extends TaskFilter
{
  reportType: TaskReportType;
}

enum TaskReportType
{
  General,
  TaskTimes,
  UserTimes
}

@Injectable({
  providedIn: 'root'
})
export class TasksService
{

  constructor(private authService: AuthService, private docService: DocumentService,
              @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
              private log: LoggingService, private textsFormattingService: TextsFormattingService,
  )
  { }
  // TODO emit this event and
  // - update tasklist if its about subtask changes, if not reset the list
  // - update the task states in workmanager
  // - update the current task in work manager
  // - update the list of related tasks in work manager
  taskChanged: EventEmitter<TaskEvent> = new EventEmitter<TaskEvent>();

  // WEB 2.0 MODIFIY DOCUMENTS - SAVE DOCUMENT LIST
  // TODO - Improve this. selectedDocumentsFromSearch should not be stored in the service!
  selectedDocumentsFromSearch: MoonDeskDocument [];
  preSelectedReviewers: ReviewerAssignment [] = [];
  preSelectedTaskName: string;

  private emitTaskChangedEvent(event: 'TaskCreated'|'TaskUpdated'|'SubTaskChanged'|'SubTaskAdded', task: MoonTask, subTask: SubTask)
  {
    this.taskChanged.emit({
      event: event,
      task: task,
      subTask: subTask
    });
  }

  async queryTask(taskQuery: TaskQuery): Promise<MoonTask>
  {
    if (!taskQuery.companyId)
    {
      taskQuery.companyId = this.authService.getCurrentIdentity().company.id;
    }
    const data = await this.authService.authPost<MoonTask>('/api/tasks/queryTask', taskQuery);
    this.fixTimestamps([data]);
    this.printReviewerUrls([data]);
    await this.addLibContentsImgUrl(data.documentContents);
    data.contentGroups = this.createContentList(data.contentGroups, data.documentContents);
    return data;
  }

  async querySubTask(subTaskQuery: SubTaskQuery): Promise<SubTask>
  {
    const data = await this.authService.authPost<SubTask>('/api/tasks/querySubTask', subTaskQuery);
    this.fixSubTaskTimestamps(data);
    // this.fixSubTaskMeasurements(data);
    return data;
  }

  async getPendingTasksNames(page: number): Promise<PagedResponse<TaskWithNameDto>>
  {
    const identity = this.authService.getCurrentIdentity();
    const params: [string, string][] =
    [
      ['companyId', identity?.company?.id],
      ['page', `${page}`]
    ];
    const response = await this.authService.authGet<PagedResponse<TaskWithNameDto>>('/api/v2/tasks/getPendingTasksNames', params);
    return response;
  }

  async searchTasksDTO(taskFilter: TaskFilter): Promise<PagedResponse<TaskSearchDto>>
  {
    taskFilter = this.prepareFilter(taskFilter);
    console.log('Filtering tasks by: ');
    console.log(taskFilter);

    const response = await this.authService.authGetObjectParam<PagedResponse<TaskSearchDto>>('/api/v2/tasks/searchTasksTest', taskFilter);

    response.result?.forEach(t =>
    {
      t.expirationTimestampUtc = new Date(t.expirationTimestampUtc);
      t.subTasks?.forEach(st => st.lastUpdateUtc = new Date(st.lastUpdateUtc));
    });

    return response;
  }

  async searchShareTasks(taskFilter: TaskFilter): Promise<PagedResponse<ShareTaskDto>>
  {
    taskFilter = this.prepareFilter(taskFilter);
    console.log('Filtering tasks by: ');
    console.log(taskFilter);

    const response = await this.authService.authGetObjectParam<PagedResponse<ShareTaskDto>>(
      '/api/v2/tasks/searchShareTasks',
      taskFilter);

    response.result?.forEach(t =>
    {
      t.expirationTimestampUtc = new Date(t.expirationTimestampUtc);
      t.creationTimestampUtc = new Date(t.creationTimestampUtc);
      t.lastDownloadTimestampUtc = t.lastDownloadTimestampUtc !== null ? new Date(t.lastDownloadTimestampUtc) : null;
    });

    return response;
  }

  async searchExtensionTasks(taskFilter: TaskFilter): Promise<PagedResponse<TaskForExtensionDto>>
  {
    taskFilter = this.prepareFilter(taskFilter);
    console.log('Filtering tasks by: ');
    console.log(taskFilter);

    const response = await this.authService.authGetObjectParam<PagedResponse<TaskForExtensionDto>>(
      '/api/v2/tasks/searchTasksForExtension',
      taskFilter);

    response.result?.forEach(t =>
    {
      t.expirationTimestampUtc = new Date(t.expirationTimestampUtc);
    });

    return response;
  }

  async getUsersWithTasks(taskFilter: TaskFilter): Promise<UserWithTaskDto[]>
  {
    taskFilter = this.prepareFilter(taskFilter);
    const response = await this.authService.authGetObjectParam<UserWithTaskDto[]>('/api/v2/tasks/getUsersWithTasks', taskFilter);
    return response;
  }

  async getProjectsWithTasks(taskFilter: TaskFilter): Promise<ProjectWithTaskCountDto[]>
  {
    taskFilter = this.prepareFilter(taskFilter);
    const response = await this.authService.authGetObjectParam<ProjectWithTaskCountDto[]>('/api/v2/tasks/getProjectsWithTasks', taskFilter);
    return response;
  }

  async searchTemplateTasks(page: number): Promise<TasksResponse>
  {
    const identity = this.authService.getCurrentIdentity();
    const taskFilter: TaskFilter =
    {
      companyId: identity?.company?.id,
      page: page
    };
    const data: TasksResponse = await this.authService.authPost<TasksResponse>('/api/tasks/searchTemplateTasks', taskFilter);
    return data;
  }

  async getTasksForReview(page: number, searchText?: string, taskNumber?: number): Promise<PagedResponse<TaskForReviewDto>>
  {
    const identity: Identity = this.authService.getCurrentIdentity();
    const params: [string, string][] =
    [
      ['companyId', identity?.company?.id],
      ['page', `${page}`],
      ['searchText', searchText],
      ['taskNumber', taskNumber ? `${taskNumber}` : null]
    ];
    const response = await this.authService.authGet<PagedResponse<TaskForReviewDto>>('/api/v2/tasks/getTasksForReview', params);
    response.result?.forEach(t => t.expirationDate = new Date(t.expirationDate));
    return response;
  }

  private prepareFilter(taskFilter: TaskFilter): TaskFilter
  {
    // to not interfere with form data, we create a copy
    let filterCopy: TaskFilter = <any>{};
    filterCopy = Object.assign(filterCopy, taskFilter);
    taskFilter = <any>filterCopy;

    // the only way to really be sure that a local timezone selection of dates
    // reaches the backend in utc AND covers the full minDate (starting at 00:00:00)
    // until the full maxDate (ending at 23:59:59) is to use these two if's:
    if (taskFilter.minDate)
    {
      const x = new Date(taskFilter.minDate);
      taskFilter.minDate = <any>`${x.getFullYear()}-${x.getMonth() + 1}-${x.getDate()}T00:00:00Z`;
    }
    if (taskFilter.maxDate)
    {
      const x = new Date(taskFilter.maxDate);
      taskFilter.maxDate = <any>`${x.getFullYear()}-${x.getMonth() + 1}-${x.getDate()}T23:59:59Z`;
    }
    if (!taskFilter.taskNumber)
    {
      taskFilter.taskNumber = 0;
    }
    if (!taskFilter.tasksWithoutProjects)
    {
      taskFilter.tasksWithoutProjects = false;
    }
    const identity: Identity = this.authService.getCurrentIdentity();
    filterCopy.companyId = identity.company.id;
    return filterCopy;
  }

  download(taskId: string, attachment: MoonTaskAttachment, environmentBackendUrl: string)
  {
    try
    {
      console.log(`Downloading attachment ${attachment.name} of task ${taskId}`);
      const url = `${environmentBackendUrl}${this.getDownloadUrl(taskId, attachment)}`;
      this.authService.webDownload(url);
    }
    catch (err)
    {
      console.log('Download error');
      console.log(err);
    }
  }

  private getDownloadUrl(taskId: string, attachment: MoonTaskAttachment): string
  {
    const identity = this.authService.getCurrentIdentity();
    let token: string;
    if (identity.user)
    {
      token = identity.user.token;
    }
    else if (identity.reviewer)
    {
      token = identity.reviewer.token;
    }
    const httpParams = new HttpParams({
      fromObject: {
        taskId: taskId,
        filename: attachment.name,
        access_token: token
      }
    });
    const url = `/api/tasks/downloadAttachment?` + httpParams.toString().replace(/\+/gi, '%2B');
    return url;
  }

  /**
   * To be used in Illustrator.
   */
  async downloadAttachment(task: MoonTask, attachment: MoonTaskAttachment, progressCallback?: (progress: Progress) => void): Promise<any>
  {
    const identity = this.authService.getCurrentIdentity();
    let token: string;
    if (identity.user)
    {
      token = identity.user.token;
    }
    else if (identity.reviewer)
    {
      token = identity.reviewer.token;
    }
    const params: [string, string][] =
    [
      ['taskId', task.id],
      ['filename', attachment.name],
      ['access_token', token]
    ];
    const result = await this.authService.longGet(`/api/tasks/downloadAttachment`, false, params, progressCallback);
    return result;
  }

  async addSubTask(
    taskId: string,
    documentId: string,
    status: 'ToDo' | 'InReview' | 'Done' | 'Closed' = 'ToDo',
    version?: number): Promise<MoonTask>
  {
    const params: [string, string][] = [
      ['taskId', taskId],
      ['documentId', documentId],
      ['status', status],
      ['version', `${version}`]
    ];
    const result = await this.authService.authGet<MoonTask>('/api/tasks/addSubTask', params);
    this.fixTimestamps([result]);
    return result;
  }

  async postTaskProject(taskProject: TaskProject): Promise<TaskProject>
  {
    const identity = this.authService.getCurrentIdentity();
    const taskProjectToSend = _.clone(taskProject);
    taskProjectToSend.companyId = identity.company.id;
    const result = await this.authService.authPost<TaskProject>('/api/tasks/postTaskProject', taskProjectToSend);
    return result;
  }

  async setTaskProject(taskId: string, taskProjectId: string): Promise<TaskProject>
  {
    const params: [string, string][] = [
      ['taskId', taskId],
      ['taskProjectId', taskProjectId]
    ];
    const result = await this.authService.authGet<TaskProject>('/api/tasks/setTaskProject', params);
    return result;
  }

  async getTaskProjects(): Promise<TaskProject[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity.company.id;
    const params: [string, string][] = [['companyId', companyId]];
    const result = await this.authService.authGet<TaskProject[]>('/api/tasks/getTaskProjects', params);
    return result;
  }

  async getSubTaskMessages(filter: SubTaskMessageFilter): Promise<SubTaskMessageResponse>
  {
    const response = await this.authService.authPost<SubTaskMessageResponse>('/api/tasks/getSubTaskMessages', filter);

    response.messages?.forEach(m =>
    {
      m.timestampUtc = new Date(m.timestampUtc);
      if (m.deletedTimestampUtc)
      {
        m.deletedTimestampUtc = new Date(m.deletedTimestampUtc);
      }
    });
    return response;
  }

  async getTaskMessages(filter: TaskMessageFilter): Promise<TaskMessageResponse>
  {
    const response = await this.authService.authPost<TaskMessageResponse>('/api/tasks/getTaskMessages', filter);

    response.messages?.forEach(m =>
    {
      m.timestampUtc = new Date(m.timestampUtc);
      if (m.deletedTimestampUtc)
      {
        m.deletedTimestampUtc = new Date(m.deletedTimestampUtc);
      }
    });
    return response;
  }

  async deleteTaskProject(taskProjectId: string): Promise<void>
  {
    const params: [string, string][] = [['taskProjectId', taskProjectId]];
    await this.authService.authDelete<TaskProject[]>('/api/tasks/deleteTaskProjects', params);
  }

  async postReviewersGroup(reviewerGroup: ReviewersGroup): Promise<ReviewersGroup>
  {
    const identity = this.authService.getCurrentIdentity();
    reviewerGroup.companyId = identity.company.id;
    const result = await this.authService.authPost<ReviewersGroup>('/api/tasks/postReviewersGroup', reviewerGroup);
    return result;
  }

  async getReviewersGroups(): Promise<ReviewersGroup[]>
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity.company.id;
    const params: [string, string][] = [['companyId', companyId]];
    const result = await this.authService.authGet<ReviewersGroup[]>('/api/tasks/getReviewersGroups', params);
    return result;
  }

  async deleteReviewersGroup(reviewersGroupId: string): Promise<void>
  {
    const params: [string, string][] = [['reviewersGroupId', reviewersGroupId]];
    await this.authService.authDelete<void>('/api/tasks/deleteReviewersGroup', params);
  }

  // TODO remove return object 'task' - two versions ahead, we can remove the return object from the endpoint
  // (it's too complex and will break the db queries). instead, the using component should re-load the task
  // and subtask objects itself after 'save'
  async postTask(task: MoonTask, files: File[], progressCallback?: (progress: Progress) => void): Promise<MoonTask>
  {
    // Remove circular dependencies (When we update CEP to v12 we can use structuredClone() instead of JSON.parse(JSON.stringify()))
    task.subTasks?.forEach(st => st.document.latestVersion.document = null);
    task.taskDocumentTemplates?.forEach(tdt =>
    {
      if (tdt.document?.latestVersion?.document)
      {
        tdt.document.latestVersion.document = null
      }
    });

    const taskToSend: MoonTask = JSON.parse(JSON.stringify(task));

    taskToSend.company = { id: this.authService.getCurrentIdentity().company.id };
    taskToSend.subTasks = _.map(taskToSend.subTasks, subTask =>
    {
      const x = _.clone(subTask);
      x.documentVersions = undefined;
      x.document =
      {
        id: x.document.id,
        documentType: null,
        classValues: null,
        companyId:
        taskToSend.company.id,
        company: null,
        latestVersion: null
      };
      return x;
    });
    if (taskToSend.contentGroups)
    {
      this.parseContentList(taskToSend);
    }
    taskToSend.documentContents?.forEach(dc =>
    {
      if (dc.type === 'LibraryElement')
      {
        dc.documentVersionId = dc.documentVersion.id;
        dc.documentVersion = undefined;
      }
    });
    if (taskToSend.findAndReplace && taskToSend.findAndReplace.length > 0)
    {
      taskToSend.findAndReplace = _.filter(taskToSend.findAndReplace, fr =>
        (fr.findValue !== null && fr.findValue !== '') && (fr.replaceValue !== null && fr.replaceValue !== ''));
    }

    taskToSend.taskDocumentTemplates = _.map(taskToSend.taskDocumentTemplates, tdt =>
    {
      const x = _.clone(tdt);
      x.document =
      {
        id: x.document.id,
        name: x.document.name ?? null,
        documentType: x.document.documentType ?? null,
        docTypeName: x.document.docTypeName ?? null,
        classValues: x.document.classValues ?? null,
        companyId: taskToSend.company.id,
        company: null,
        latestVersionId: x.document.latestVersionId ?? x.document.latestVersion?.id,
        latestVersion: null
      };
      return x;
    });

    const formData = new FormData();
    if (files && files.length > 0)
    {
      files.forEach(f => formData.append('file', f, f.name));
    }
    formData.append('task', JSON.stringify(taskToSend));
    if (!taskToSend.id)
    {
      this.log.logHubspot_CreateTask();
    }
    const result = await this.authService.longPost<MoonTask>(`/api/tasks/postTaskForm`, formData, progressCallback);
    this.fixTimestamps([result]);
    this.printReviewerUrls([result]);
    result.contentGroups = this.createContentList(result.contentGroups, result.documentContents);
    return result;
  }

  async updateShareTaskExpirationDate(taskId: string, expirationDate: Date): Promise<Date>
  {
    const identity = this.authService.getCurrentIdentity();
    const request: UpdateShareTaskExpirationDateRequest =
    {
      companyId: identity?.company?.id,
      taskId: taskId,
      expirationDate: expirationDate
    };

    const result = await this.authService.authPost<Date>('/api/v2/tasks/updateShareTaskExpirationDate', request);
    return new Date(result);
  }

  /**
   * Related tasks that are InWork or InReview
   */
  async getTasksForDocuments(documentIds: string[]): Promise<MoonTask[]>
  {
    if (!this.authService.getCurrentIdentity().company?.subscriptionPlan?.hasTaskModule)
    {
      return null;
    }
    const tasks = await this.authService.authPost<MoonTask[]>('/api/tasks/getTasksForDocuments', documentIds);
    this.fixTimestamps(tasks);
    return tasks;
  }

  async getDocumentTaskHistory(request: DocumentTaskHistoryRequest): Promise<DocumentTaskHistoryResult>
  {
    const result = await this.authService.authPost<DocumentTaskHistoryResult>('/api/tasks/getDocumentTaskHistory', request);
    result.documentTaskHistories.forEach(dth =>
    {
      dth.subTaskVersionReports.forEach(svr => this.fixReviewStatuses(svr.reviewStates));
      this.fixTimestamps([dth.task]);
      this.fixSubTaskTimestamps(dth.subTask);
    });
    return result;
  }

  async changeStatus(subTaskId: string, status: 'ToDo' | 'InReview' | 'Done' | 'Closed')
  {
    const st = await this.authService.authPost<SubTask>('/api/tasks/changeStatus', {subTaskId: subTaskId, status: status});
    this.taskChanged.emit({subTask: st, task: undefined, event: 'SubTaskChanged'});
  }
  async changeAssigned(assigned: TaskAssignedPost): Promise<MoonTask>
  {
    const task = await this.authService.authPost<MoonTask>('/api/tasks/changeAssigned', assigned);
    return task;
  }
  async archiveTask(taskId: string)
  {
    await this.authService.authPost<void>('/api/tasks/archiveTask', { id: taskId });
  }


  async updateTaskTemplateStatus(taskId: string, isTemplate: boolean)
  {
    const params: [string, string][] = [
      ['taskId', taskId],
      ['isTemplate', isTemplate ? 'true' : 'false']
    ];
    await this.authService.authGet<void>('/api/tasks/updateTaskTemplateStatus', params);
  }

  async recoverTask(taskId: string)
  {
    await this.authService.authPost<void>('/api/tasks/recoverTask', { id: taskId });
  }

  async getCompanyUsers(companyId: string): Promise<User[]>
  {
    const params: [string, string][] = [['companyId', companyId]];
    const result = await this.authService.authGet<User[]>('/api/tasks/getCompanyUsers', params);
    return result;
  }

  async postAction(subTaskId: string, documentVersion: number,
    action: 'Approve' | 'Reject' | 'Reopen',
    newDocumentStatus: 'Draft' | 'Approved' | 'Preapproved' | null,
    subTaskMessage?: SubTaskMessage,
    rejectReason?: RejectReason): Promise<SubTask>
  {
    const formData = new FormData();

    if (subTaskMessage?.attachment?.file)
    {
      const file = subTaskMessage.attachment.file;
      formData.append('file', file, file.name);
    }

    const postData: TaskActionPost =
    {
      action: action,
      documentVersion: documentVersion,
      newDocumentStatus: newDocumentStatus,
      subTaskId: subTaskId,
      subTaskMessage: subTaskMessage,
      rejectReason: rejectReason
    };

    formData.append('taskActionPost', JSON.stringify(postData));

    const result = await this.authService.authPost<SubTask>('/api/tasks/postAction', formData);
    if (result)
    {
      this.fixSubTaskTimestamps(result);
      _.forEach(result.documentVersions, r => r.imageUrl = this.docService.getFullImageUrl(r));
      // result.document.latestVersion  = _.find(result.documentVersions , dv => dv.id === result.document.latestVersionId);
      result.document.latestVersion = <DocumentVersion> _.max(result.documentVersions, dv => dv.versionNumber);
      result.document.latestVersion.imageUrl = this.docService.getFullImageUrl(result.document.latestVersion);
    }
    return result;
  }

  async sendMessage(msg: SubTaskMessage): Promise<SubTaskMessage>
  {
    const formData = new FormData();
    if (msg.attachment && msg.attachment.file)
    {
      const file = msg.attachment.file;
      formData.append('file', file, file.name);
    }
    formData.append('subTaskMessage', JSON.stringify(msg));

    const result = await this.authService.authPost<SubTaskMessage>('/api/tasks/sendMessage', formData);
    if (result)
    {
      result.timestampUtc = new Date(result.timestampUtc);
      if (result.deletedTimestampUtc)
      {
        result.deletedTimestampUtc = new Date(result.deletedTimestampUtc);
      }
    }
    return result;
  }

  async sendTaskMessage(msg: TaskMessage): Promise<TaskMessage>
  {
    const result = await this.authService.authPost<TaskMessage>('/api/tasks/sendTaskMessage', msg);
    if (result)
    {
      result.timestampUtc = new Date(result.timestampUtc);
      if (result.deletedTimestampUtc)
      {
        result.deletedTimestampUtc = new Date(result.deletedTimestampUtc);
      }
    }
    return result;
  }

  async deleteMessage(msg: SubTaskMessage): Promise<SubTaskMessage>
  {
    const params: [string, string][] = [['messageId', msg.id]];
    const result = await this.authService.authGet<SubTaskMessage>('/api/tasks/deleteMessage', params);
    if (result)
    {
      result.timestampUtc = new Date(result.timestampUtc);
      result.deletedTimestampUtc = new Date(result.deletedTimestampUtc);
    }
    return result;
  }

  async getReviewers(companyId: string): Promise<Reviewer[]>
  {
    const params: [string, string][] = [['companyId', companyId]];
    const result = await this.authService.authGet<Reviewer[]>('/api/tasks/getReviewers', params);
    return result;
  }

  getTaskTooltip(task: MoonTask)
  {
    const doneCount = _.filter(task.subTasks, t => t.status === 'Done').length;
    const closedCount = _.filter(task.subTasks, t => t.status === 'Closed').length;
    const toDoCount = _.filter(task.subTasks, t => t.status === 'ToDo').length;
    const inReviewCount = _.filter(task.subTasks, t => t.status === 'InReview').length;
    return 'ToDo: ' + toDoCount + ' | InReview: ' + inReviewCount + ' | Done: ' + doneCount + ' | Closed: ' + closedCount;
  }

  /**
   * task OR subTasks, if both, subTasks will be ignored
   */
  getTasksStatus(task?: MoonTask, subTasksFromTask?: SubTask[]): 'In Work' | 'Archived' | 'In Review' | 'Done'
  {
    if (task && task.archived)
    {
      return 'Archived';
    }
    const subTasks = task ? task.subTasks : subTasksFromTask;
    if (!subTasks || subTasks.length === 0)
    {
      // task is empty
      return 'In Work';
    }

    if (_.all(subTasks, st => st.status === 'Closed'))
    {
      // all subtasks are closed
      return 'In Work';
    }

    if (_.all(subTasks, st => st.status === 'Done' || st.status === 'Closed'))
    {
      // all subtasks are done or closed
      return 'Done';
    }

    if (_.all(subTasks, st => st.status === 'InReview' || st.status === 'Done' || st.status === 'Closed'))
    {
      return 'In Review';
    }

    return 'In Work';
  }

  private fixTimestamps(tasks: MoonTask[])
  {
    for (const t of tasks)
    {
      t.timestampUtc = new Date(t.timestampUtc);
      t.expirationTimestampUtc = new Date(t.expirationTimestampUtc);
      t.lastUpdateTimestampUtc = new Date(t.lastUpdateTimestampUtc);
      if (t.moonTask_Audits)
      {
        _.forEach(t.moonTask_Audits, a => a.timestampUtc = new Date(a.timestampUtc));
      }
      if (t.taskMessages)
      {
        _.forEach(t.taskMessages, m => m.timestampUtc = new Date(m.timestampUtc));
      }
      if (t.subTasks)
      {
        _.forEach(t.subTasks, subTask => this.fixSubTaskTimestamps(subTask));
      }
    }
  }

  private fixSubTaskTimestamps(subTask: SubTask)
  {
    subTask.lastUpdateUtc = new Date(subTask.lastUpdateUtc);
    if (subTask.subTaskMessages)
    {
      subTask.subTaskMessages.forEach(m =>
      {
        m.timestampUtc = new Date(m.timestampUtc);
        if (m.deletedTimestampUtc)
        {
          m.deletedTimestampUtc = new Date(m.deletedTimestampUtc);
        }
      });
    }
    if (subTask.subTask_Audits)
    {
      subTask.subTask_Audits.forEach(a => a.timestampUtc = new Date(a.timestampUtc));
    }
    if (subTask.documentVersions)
    {
      subTask.documentVersions.forEach(v =>
      {
        v.timestampUtc = new Date(v.timestampUtc);
        v.approvedRejectedTimestampUtc = new Date(v.approvedRejectedTimestampUtc);
      });
    }
    if (subTask.document && subTask.document.latestVersion)
    {
      subTask.document.latestVersion.timestampUtc = new Date(subTask.document.latestVersion.timestampUtc);
    }
    this.fixReviewStatuses(subTask.reviewStatuses);
  }

  private fixReviewStatuses(reviewStatuses: ReviewStatus [])
  {
    if (reviewStatuses)
    {
      reviewStatuses.forEach(rs =>
      {
        if (rs.answerTimestampUtc)
        {
          rs.answerTimestampUtc = new Date(rs.answerTimestampUtc);
        }
        if (rs.seenTimestampUtc)
        {
          rs.seenTimestampUtc = new Date(rs.seenTimestampUtc);
        }
      });
    }
  }

  private async addLibContentsImgUrl(documentContents: DocumentContent[])
  {
    if (documentContents && documentContents.length > 0)
    {
      for(const dc of documentContents)
      {
        if (dc.documentVersion)
        {
          dc.documentVersion.imageUrl = await this.docService.getFileUrl(dc.documentVersion, 'previewImage.png');
        }
      }
    }
  }

  /**
   * The Backend works with two plain list "contentGroups: ContentGroup[]" and "documentContents: DocumentContent[]"
   * but the frontend needs one list "contentGroups: ContentGroup[]" whit the reference to each docContents inside that group
   */
  createContentList(contentGroups: ContentGroup[], documentContents: DocumentContent[]): ContentGroup[]
  {
    if (!documentContents)
    {
      return [];
    }
    documentContents.forEach(dc =>
    {
      const group = _.find(contentGroups, cg => cg.id === dc.contentGroupId);
      if (group != null)
      {
        if (!group.documentContents)
        {
          group.documentContents = [];
        }
        group.documentContents.push(dc);
      }
      else
      {
        // Only for old tasks - should not happend for new tasks (a group must exist to create a content)
        let ungroupedGroup = _.find(contentGroups, cg => cg.name === 'Ungrouped');
        if (ungroupedGroup)
        {
          if (!ungroupedGroup.documentContents)
          {
            ungroupedGroup.documentContents = [];
          }
          ungroupedGroup.documentContents.push(dc);
        }
        else
        {
          const lastGroup = <ContentGroup> _.max(contentGroups, cg => cg.number);
          const lastNumber = lastGroup && lastGroup.number ? lastGroup.number : 0;
          ungroupedGroup =
          {
            name: 'Ungrouped',
            number: lastNumber + 1,
            documentContents: [dc]
          };
          contentGroups.push(ungroupedGroup);
        }
      }
    });
    contentGroups.forEach(cg =>
    {
      cg.documentContents = _.sortBy(cg.documentContents, dc => dc.number);
    });
    return _.sortBy(contentGroups, cg => cg.number);
  }

  /**
   * The backend is waiting for two plain list "contentGroups: ContentGroup[]" and "documentContents: DocumentContent[]"
   * where the reference to the groups is in the docContents and not in the contentGroup (contentGroup.docContens = null)
   * so we create the lists from the "contentGroups: ContentGroup[]"
   */
  parseContentList(task: MoonTask)
  {
    // 1. Remove empty contents
    task.contentGroups.forEach(cg =>
    {
      cg.documentContents = _.filter(cg.documentContents, dc =>
        (dc.type === 'Text' && dc.content != null && dc.content !== '') ||
        (dc.type === 'LibraryElement' && (dc.documentVersion != null || (dc.documentVersionId != null && dc.documentVersionId !== ''))));
      // 1.2. Reorder documentContents numbers
      cg.documentContents = _.sortBy(cg.documentContents, dc => dc.number);
      let dcNumber = 1;
      cg.documentContents.forEach(dc =>
      {
        dc.number = dcNumber++;
      });
    });

    // 2. Remove empty groups
    task.contentGroups = _.filter(task.contentGroups, cg => cg.documentContents && cg.documentContents.length > 0);
    // 3. Reorder contentGroups numbers, name unnamed groups and rename groups with duplicated names
    task.contentGroups = _.sortBy(task.contentGroups, cg => cg.number);
    let cgNumber = 1;
    task.contentGroups.forEach(cg =>
    {
      cg.number = cgNumber++;
      if (!cg.name)
      {
        cg.name = `Group ${cg.number}`;
      }
      if (_.any(task.contentGroups, tcg => tcg !== cg && tcg.name.toLowerCase() === cg.name.toLowerCase()))
      {
        cg.name = `${cg.name} (${cg.number})`;
      }
    });
    // 4. Get all documentContents inside the groups
    const docContentsPlainList: DocumentContent[] = [];
    task.contentGroups.forEach(cg =>
    {
      if (!task.id)
      {
        cg.documentContents.forEach(dc =>
        {
          if (!cg.id)
          {
            dc.contentGroup = cg;
          }
          else
          {
            dc.contentGroup = null;
            dc.contentGroupId = cg.id;
          }
          if (!dc.minSize)
          {
            dc.minSize = 0;
          }
          docContentsPlainList.push(dc);
        });
      }
      else
      {
        cg.documentContents.forEach(dc =>
        {
          if (!cg.id)
          {
            dc.contentGroup = cg;
          }
          else
          {
            dc.contentGroup = null;
            dc.contentGroupId = cg.id;
          }
          if (!dc.minSize)
          {
            dc.minSize = 0;
          }
          docContentsPlainList.push(dc);
        });
      }
      cg.documentContents = [];
    });
    task.documentContents = docContentsPlainList;
  }

  private printReviewerUrls(tasks: MoonTask[])
  {
    for (const t of tasks)
    {
      if (t.reviewerAssignments)
      {
        for (const ra of t.reviewerAssignments)
        {
          if (ra.reviewer)
          {
            console.log(`Url for Reviewer ${ra.reviewer.fullname}: ${ra.url}`);
          }
          else if (ra.user)
          {
            console.log(`Url for User ${ra.user.username}: ${ra.url}`);
          }
        }
      }
    }
  }

  private async markAsSeen(subTaskId: string, version: number): Promise<SubTask>
  {
    const params: [string, string][] = [
      ['subTaskId', subTaskId],
      ['version', `${version}`]
    ];
    const result = await this.authService.authGet<SubTask>('/api/tasks/markAsSeen', params);
    if (result)
    {
      this.fixSubTaskTimestamps(result);
    }
    return result;
  }

  async webDownloadTaskReport(filter: TaskFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getTaskReportUrl(filter, TaskReportType.General);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Tasks_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  async webDownloadTaskHistoryEventsReport(filter: TaskFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getTaskReportUrl(filter, TaskReportType.TaskTimes);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Tasks_Times_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  async webDownloadUsersTaskHistoryEventsReport(filter: TaskFilter): Promise<void>
  {
    let url: string;
    try
    {
      url = await this.getTaskReportUrl(filter, TaskReportType.UserTimes);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Users_Tasks_Times_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  async webDownloadSubTaskMessagesReport(subTaskId: string): Promise<void>
  {
    let url: string;
    try
    {
      const timezoneOffset = new Date().getTimezoneOffset();
      const params: [string, string][] = [
        ['subTaskId', subTaskId],
        ['timezoneOffset', `${timezoneOffset}`]
      ];
      const data = await this.authService.authGet<Blob>('/api/tasks/downloadSubTaskMessagesReport', params, true);
      url = window.URL.createObjectURL(data);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = `Messages_${this.authService.getCurrentIdentity().company.name}.xlsx`;
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  private async getTaskReportUrl(filter: TaskFilter, reportType: TaskReportType): Promise<string>
  {
    filter = this.prepareFilter(filter);
    const postData: DownloadTasksReportPost =
    {
      ...filter,
      reportType: reportType
    };
    const data = await this.authService.authPost<Blob>('/api/v2/tasks/DownloadTasksReport', postData, true);
    return window.URL.createObjectURL(data);
  }


  webDownloadTaskPdf(taskId: string)
  {
    let url: string;
    try
    {
      url = this.getTaskPdfUrl(taskId);
      const fileLink = document.createElement('a');
      fileLink.href = url;
      // fileLink.target = '_blank';
      fileLink.click();
    }
    finally
    {
      if (url)
      {
        this.revokeReportUrl(url);
      }
    }
  }

  private getTaskPdfUrl(taskId: string): string
  {
    const identity = this.authService.getCurrentIdentity();
    let token: string;
    if (identity.user)
    {
      token = identity.user.token;
    }
    else if (identity.reviewer)
    {
      token = identity.reviewer.token;
    }
    const url = `/api/tasks/downloadTaskPdf?taskId=${taskId}&access_token=${token}`;
    return url;
  }


  revokeReportUrl(url: string)
  {
    window.URL.revokeObjectURL(url);
  }

  /**
   * Marks the subtask as seen for the current user or reviewer.
   * Does not do anything if the current user is not a reviewer.
   * Updates the reviewStatus array for the subtask
   */
  async markAsSeenForCurrentUser(task: MoonTask, subTask: SubTask, version: number)
  {
    const id = this.authService.getCurrentIdentity();
    let newStatus: ReviewStatus;
    let oldStatus: ReviewStatus;

    const userId = id.user?.id;
    const reviewerId = id.reviewer?.id;

    const isTaskReviewer = _.any(task.reviewerAssignments, ra =>
      (ra.userId && ra.userId === userId) ||
      (ra.reviewerId && ra.reviewerId === reviewerId));

    if (!isTaskReviewer)
    {
      return;
    }

    const newSubTask = await this.markAsSeen(subTask.id, version);
    if (id.reviewer)
    {
      newStatus = _.find(newSubTask.reviewStatuses, rs => rs.reviewerId === id.reviewer.id && rs.documentVersion === version);
      oldStatus = _.find(subTask.reviewStatuses, rs => rs.reviewerId === id.reviewer.id && rs.documentVersion === version);
    }
    else if (id.user && _.some(task.reviewerAssignments, ra => ra.userId === id.user.id))
    {
      newStatus = _.find(newSubTask.reviewStatuses, rs => rs.userId === id.user.id && rs.documentVersion === version);
      oldStatus = _.find(subTask.reviewStatuses, rs => rs.userId === id.user.id && rs.documentVersion === version);
    }
    if (oldStatus && newStatus)
    {
      const statusIndex = _.indexOf(subTask.reviewStatuses, oldStatus);
      subTask.reviewStatuses.splice(statusIndex, 1, newStatus);
    }
    else if (newStatus)
    {
      subTask.reviewStatuses.push(newStatus);
    }
  }

  getRulesFromContent(documentContents: DocumentContent[], findText: string): RuleConfiguration[]
  {
    const result: RuleConfiguration[] = [];
    const company = this.authService.getCurrentIdentity().company;

    let sortedDocContents = _.sortBy(documentContents, dc => dc.number);
    sortedDocContents = _.sortBy(documentContents, dc => dc.contentGroup?.number);

    const textContents = _.filter(sortedDocContents, dc => dc.type === 'Text');
    const libraryContents = _.filter(sortedDocContents, dc => dc.type === 'LibraryElement');

    textContents?.forEach((docContent: DocumentContent) =>
    {
      const cleanContent = this.textsFormattingService.formatHtml(docContent.content, true, false);
      const contentWithBoldTags = this.textsFormattingService.cleanHtml(docContent.content, true, true);

      let ruleDescription = cleanContent + ' - should exist';
      // let richTextDescription = contentWithBoldTags + ' - should exist';
      if (docContent.minSize && docContent.minSize > 0)
      {
        ruleDescription = ruleDescription + ' with a minimun height of ' + docContent.minSize + 'mm';
        // richTextDescription = richTextDescription + ' with a minimun height of ' + docContent.minSize + 'mm';
      }
      const textWithBoldMarks = this.textsFormattingService.formatHtml(docContent.content, true, true);
      /* End of logic */
      const textRuleConfiguration: ContentTextRuleConfiguration = {
        findCondition: 'exist',
        findText: findText,
        checkCondition: 'exist',
        checkText: cleanContent,
        dirtyCheckText : textWithBoldMarks,
        textSize: docContent.minSize,
        maximumTextSize: docContent.maxSize,
        ruleDescription: ruleDescription,
        // richTextDescription: richTextDescription,
        richCheckText: contentWithBoldTags,
        checkTextCaseSensitive: true,
        checkTextBasedOnMaxSize: docContent.checkTextBasedOnMaxSize
      };
      result.push({
        companyId: company.id,
        ruleID: RuleIDs.TEXT_CONTENT,
        ruleOptions: JSON.stringify(textRuleConfiguration),
        isFromTask: true,
        documentContentId: docContent.id
      });
    });
    libraryContents?.forEach((docContent: DocumentContent) =>
    {
      const name = this.getDocVersionName(docContent.documentVersion);

      const libraryRuleConfiguration: ContentLibraryRuleConfiguration = {
        docVersions: [
          {
            versionId: docContent.documentVersionId,
            docName: docContent.documentVersion.documentName,
            width: docContent.libContentWidth,
            height: docContent.libContentHeight,
            logicalOperatorSize: docContent.libContentLogicalOperatorSize
          }
        ],
        logicalOperator: 'and',
        ruleDescription: `The image "${name}" - should exist`
      };
      result.push({
        companyId: company.id,
        ruleID: RuleIDs.LIBRARY_CONTENT,
        ruleOptions: JSON.stringify(libraryRuleConfiguration),
        isFromTask: true,
        documentContentId: docContent.id
      });
    });
    return result;
  }

  async getShareUrl(shareTask: ShareTaskDto)
  {
    const identity = this.authService.getCurrentIdentity();
    const companyId = identity?.company?.id;
    if (!companyId)
    {
      return;
    }
    const currentDate = new Date();
    if (shareTask.expirationTimestampUtc < currentDate)
    {
      throw new Error('Task is expired');
    }
    const anonymousReviewerId = shareTask.anonymousReviewerId;
    if (!anonymousReviewerId)
    {
      const request: AddAnonymousReviewerRequest = {taskId: shareTask.taskId};
      const ra = await this.authService.authPost<ReviewerAssignment>('/api/tasks/addAnonymousReviewerToTask', request);
      shareTask.anonymousReviewerId = ra.reviewerId;
    }

    const base = window.location.origin;
    const url = base + `/company/${companyId}/reviewer/${shareTask.anonymousReviewerId}/share/${shareTask.taskId}`;
    return url;
  }

  /**
   * A task report for the given user and workspace is being sent (email).
   * todo get the report object back for logging or showing to the user
   */
  async createTaskReportForUser(company: Company, user: User): Promise<boolean>
  {
    const params: [string, string][] = [
      ['userId', user.id],
      ['companyId', company.id]
    ];
    const result = await this.authService.authGet<boolean>('/api/tasks/createTaskReportForUser', params);
    return result;
  }

  /**
   * Task report for all users of the given workspace are being sent (email), if not suppressed by user(s)
   * todo get the report object back for logging or showing to the user
   */
  async createTaskReportsForWorkspace(company: Company): Promise<boolean>
  {
    const params: [string, string][] = [
      ['companyId', company.id]
    ];
    const result = await this.authService.authGet<boolean>('/api/tasks/createTaskReportsForWorkspace', params);
    return result;
  }

  async getTaskHistory(taskId: string): Promise<TaskHistory>
  {
    const params: [string, string][] = [
      ['taskId', taskId]
    ];
    const result = await this.authService.authGet<TaskHistory>('/api/tasks/getTaskHistory', params);
    result.moonTaskChanges?.forEach(mtc =>
    {
      mtc.timestampUtc = new Date(mtc.timestampUtc);
      mtc.fieldChanges?.forEach(fc =>
      {
        if (fc.fieldName === 'ExpirationTimestampUtc' || fc.fieldName === 'LastUpdateTimestampUtc' || fc.fieldName === 'LastUpdateUtc')
        {
          fc.oldValue = <any> new Date(fc.oldValue);
          fc.newValue = <any> new Date(fc.newValue);
        }
      });
    });
    result.moonTaskChanges = _.sortBy(result.moonTaskChanges, mtc => mtc.timestampUtc);
    result.moonTaskChanges.reverse();
    return result;
  }

  private fixReportTimestamps(log: TaskReportLogCompany)
  {
    log.timestampUtc = new Date(log.timestampUtc);
    if (log.userLogs)
    {
      log.userLogs.forEach(ul => ul.timestampUtc = new Date(ul.timestampUtc));
    }
  }


  private getDocVersionName(docVersion: DocumentVersion): string
  {
    let name = '';
    const tags = docVersion.documentTags;
    if (!tags || tags.length === 0)
    {
      if (docVersion.document && docVersion.uniqueExportName)
      {
        name = docVersion.uniqueExportName;
      }
      else
      {
        name = 'Untagged';
      }
    }
    else
    {
      name = _.pluck(tags, 'value').join(' - ');
      name = `${name}${docVersion.fileType}`;
    }
    return name;
  }

  async getReviewersWithPendingTasks(companyId: string): Promise<ReviewerWithPendingTasksDTO[]>
  {
    const params: [string, string][] = [
      ['companyId', companyId]
    ];
    const result = await this.authService.authGet<ReviewerWithPendingTasksDTO[]>('/api/tasks/getReviewersWithPendingTasks', params);
    return result;
  }

  async resendNotifications(taskId: string): Promise<void>
  {
    const params: [string, string][] = [
      ['taskId', taskId]
    ];
    await this.authService.authGet<TaskReportLogCompany>('/api/tasks/ResendNotifications', params);
    return;
  }

  async setDocumentPreviewUrl(st: SubTask)
  {
    let maxV: DocumentVersion;
    let url = '';
    if (st.document.latestVersion)
    {
      maxV = st.document.latestVersion;
    }
    else if (!st.documentVersions)
    {
      return;
    }
    else
    {
      maxV = <DocumentVersion>_.max(st.documentVersions, dv => dv.versionNumber);
    }
    if (!maxV.document?.previewImageUrl)
    {
      maxV.document = maxV.document ? maxV.document : <any>{};
      maxV.document.previewImageUrl = await this.docService.getFileUrl(maxV, 'previewImage.png');
      url = maxV.document.previewImageUrl;
    }
    else
    {
      url = maxV.document.previewImageUrl;
    }

    st.document.previewImageUrl = url;
  }

  getTaskClassValueStringNaming(taskClassValues: TaskClassValue []): string
  {
    const classList = this.docService.getPlainClassList(this.authService.getCurrentIdentity().company.classes);
    if(_.any(taskClassValues, tkv => tkv.classValue.classId && !tkv.classValue.class))
    {
      taskClassValues.forEach(tcv =>
      {
        tcv.classValue.class = _.find(classList, c => c.id === tcv.classValue.classId);
      });
    }
    let name = '';
    if(taskClassValues)
    {
      taskClassValues = _.sortBy(taskClassValues, tcv => tcv.classValue.class.order);
      name = taskClassValues?.map(tcv => tcv.classValue.name).join(' | ');
    }
    return name;
  }
  // TODO Remove in 1.32
  async fillTaskHistoryEvents(): Promise<void>
  {
    const identity = this.authService.getCurrentIdentity();
    if (!identity.user.isSuperUser)
    {
      return;
    }
    await this.authService.authGet<void>('/api/tasks/fillTaskHistoryEvents');
  }
}
