<div class="md-popup task-description">

  <div class="md-popup-header description-header">
    <span class="title">TASK DETAILS</span>
    <mat-progress-spinner *ngIf="busy" class="spinner" diameter="16" class="spinner" color="accent" mode="indeterminate"></mat-progress-spinner>
  </div>

  <div class="md-popup-content description-content" *ngIf="task">

    <div class="task-details">
        <div class="details">
          <div class="task-top-data">
            <span class="task-name">{{task.name}} <mat-icon (click)="goToWebsite()">open_in_new</mat-icon> </span>
            <mat-icon class="context-menu-icon" [matMenuTriggerFor]="contextMenuTask">more_horiz</mat-icon>
            <mat-menu #contextMenuTask="matMenu">
              <button  mat-menu-item matTooltip="Open task in browser" (click)="goToWebsite()">Review in Web</button>
              <button  mat-menu-item *ngIf="selectedSubTask && selectedSubTask.status == 'ToDo' && !busy" (click)="toReview()">To Review</button>
              <button
                mat-menu-item
                (click)="archiveTask()"
                [disabled]="busy">
                <span *ngIf="!task.archived">Archive task</span>
                <span *ngIf="task.archived">Recover task</span>
              </button>
            </mat-menu>
          </div>
          <div class="task-number">TASK Nº {{task.taskNumber}} <span class="creation">CREATE {{task.timestampUtc | date : 'dd/MM/yyyy'}}</span></div>

          <mat-tab-group [(selectedIndex)]="tabIndex" #tabGroup class="tab-group-task" >
            <mat-tab label="Description">
              <div class="task-description-content">
                <div class="task-description" *ngIf="task.description">
                  <div class="title">TASK DESCRIPTION<mat-icon (click)="goToWebsite()">open_in_new</mat-icon></div>
                  <div class="editor-container">
                    <span class="content-html-container" [innerHTML]="task.description">
                    </span> 
                  </div>
                </div>
                <div class="title-roles">TASK ROLES</div>
                <div class="detail-roles">
                    <div *ngIf="task.archived">Task is archived</div>
                    <div class="creator">
                      <div class="label">Creator</div>
                      <div class="user-value">{{task.createdBy.username}}</div>
                    </div>  
                    <div class="assigned">
                      <div class="label">Assigned</div>
                      <div class="user-value">{{getAssignedUsername()}}</div>
                    </div>
                </div> 
                <div class="title-status">TASK STATUS</div> 
                <div class="status">
                  <div class="label">Status</div>
                  <div class="value"> 
                    <mat-icon class="circle-icon" [ngClass]="{'inDesign' : getTaskStatus(task) === 'In Design', 
                                                              'inReview' : getTaskStatus(task) === 'In Review' , 
                                                              'done' : getTaskStatus(task) === 'Done'}"
                    >circle</mat-icon> 
                    <span class="status-text"> {{getTaskStatus(task)}}</span>
                  </div>
                </div>
                <div class="task-documents" *ngIf="task">

                  <div class="attachments">
            
                    <div class="title" *ngIf="(!task.attachments || task.attachments.length===0)">No attachments</div>
            
                    <div class="files" *ngIf="task.attachments && task.attachments.length > 0">
                      <div class="file-title">Attachments</div>   
                      <div class="file" *ngFor="let attachment of task.attachments" [matTooltip]="attachment.name">
                        <div class="file-name">{{attachment.name}}</div>
                        <div class="progress" *ngIf="attachment.progress && attachment.progress < 100">
                          <mat-progress-bar
                            color="accent"
                            mode="determinate" 
                            [value]="attachment.progress">
                          </mat-progress-bar>
                        </div>
                        <mat-icon class="notranslate download-icon" (click)="downloadAttachment(attachment)" *ngIf="!attachment.progress || attachment.progress === 100">
                          file_download
                        </mat-icon>
                      </div>
                    </div>
            
                  </div>
                  
                  <div class="footer-documents">
                    <div class="title" *ngIf="(!task.subTasks || task.subTasks.length===0)">No Documents</div>
                    <mat-tab-group class="footer-tabs">
                      <mat-tab label="Documents" *ngIf="getOpenSubTasks(task).length > 0"> 
                        <div class="subtask" *ngFor="let subTask of getOpenSubTasks(task)">
                          <div class="description"
                            (click)="selectSubTask(subTask.id)">
            
                            <div class="subtask-name uppercase" [matTooltip]="getSubTaskName(subTask)">
                              {{getSubTaskName(subTask)}}
                            </div>
                          </div>
                          <div class="moon-number">
                            <div class="moon-pill">
                              {{subTask.document.moonNumber}}
                            </div>
                          </div>
                          <div class="messages">
                            <div class="messages-status" (click)="selectSubTask(subTask.id)">
                              <div class="no-msgs" matTooltip="No messages"
                                *ngIf="subTask.subTaskInfos.lastVersionMessagesCount === 0">
                                <mat-icon class="notranslate">chat_bubble</mat-icon>
                              </div>
                              <div class="readed-msgs" matTooltip="There is not recent messages"
                                *ngIf="subTask.subTaskInfos.lastVersionMessagesCount > 0 && !subTask.subTaskInfos.hasRecentMessages">
                                <mat-icon class="notranslate">chat_bubble</mat-icon>
                              </div>
                              <div class="unreaded-msgs" matTooltip="Recent messages" *ngIf="subTask.subTaskInfos.lastVersionMessagesCount > 0 && subTask.subTaskInfos.hasRecentMessages">
                                <mat-icon class="notranslate">chat_bubble</mat-icon>
                              </div>
                            </div>
                          </div>
                          <div class="edit" (click)="editSubTask(subTask)" [matTooltip]="'Edit'"
                            [matTooltipPosition]="'above'">
                            <span>EDIT</span>
                          </div>
                          </div>
                      </mat-tab>
            
                      <mat-tab label="Templates" *ngIf="task.taskDocumentTemplates && task.taskDocumentTemplates.length>0"> 
                        <div class="subtask" *ngFor="let taskDocumentTemplate of task.taskDocumentTemplates">
                          <div class="description">
            
                            <div class="subtask-name uppercase"  [matTooltip]="taskDocumentTemplate.document.classValueString">
                              {{taskDocumentTemplate.document.classValueString ? taskDocumentTemplate.document.classValueString :
                                taskDocumentTemplate.document.moonNumber}}
                            </div>
                          </div>
                          <div class="moon-number">
                            <div class="moon-pill-template">
                              {{'T '+taskDocumentTemplate.document.moonNumber}}
                            </div>
                          </div>
                          <div class="messages">
                            <div class="messages-status">
                              <div class="no-msgs">
                                <mat-icon class="notranslate">chat_bubble</mat-icon>
                              </div>
                            </div>
                          </div>
                          <div class="edit" (click)="editTemplate(taskDocumentTemplate)" [matTooltip]="'Edit'"
                            [matTooltipPosition]="'above'">
                            <span>EDIT</span>
                          </div>
                          </div>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div> 
              </div>

            </mat-tab>

            <mat-tab label="Messages" >
              <span class="title">Comments</span>
              <div class="history" *ngIf="!busy">
                <mat-form-field  appearance="outline">
                  <mat-select #select [placeholder]="getSelectValue()" disableOptionCentering>
                    <mat-select-trigger>
                      {{getSelectValue()}}
                    </mat-select-trigger>
                    <mat-option (click)="selectSubTask(undefined)" [value]="'Task Messages'">
                      Task Messages
                    </mat-option>
                    <mat-option *ngFor="let st of task.subTasks" (click)="selectSubTask(st.id)" [value]="st.subTaskInfos.classValueString ? st.subTaskInfos.classValueString : st.document.moonNumber">
                      {{st.subTaskInfos.classValueString ? st.subTaskInfos.classValueString : st.document.moonNumber}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <ng-container *ngIf="tabIndex == 1">
                  <app-task-chat 
                    *ngIf="!selectedSubTask" 
                    [task]="task">
                  </app-task-chat>

                  <app-task-history
                    *ngIf="selectedSubTask"
                    [subTask]="selectedSubTask"
                    [showVersions]="false">
                  </app-task-history>
                </ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
    </div>
  </div>

  <div class="md-popup-actions description-actions" >
    <button
      class="md-button md-button-highlighted"
      [ngClass]="{'md-button-outstanding': taskService.getTasksStatus(task) !== 'Done'}"
      [matTooltip]="selectedSubTask ? 'Edit the current SubTask with Illustrator' : 'Edit the current Task with Illustrator'" 
      (click)="editTask()">
      Task edit
    </button>
    <button e2e="md-close-button" class="md-button" (click)="close()">Close</button>  
  </div> 

</div>