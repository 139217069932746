import { Injectable, EventEmitter, NgZone } from '@angular/core';
import * as _ from 'underscore';
import { DocumentService,
         LoggingService,
         AuthService,
         MoonDeskDocument,
         Severity,
         DocumentFilter,
         DocumentVersion,
         DocumentPost,
         Progress,
         DocumentRawData,
         LibDocTypesNames,
         DocumentVersionMetadata,
         LibraryDocForExtensionDto,
         LibraryTypeEnum
         } from '../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FilesystemService } from './filesystem.service';
import { FilepathService } from './filepath.service';
import { IllustratorService } from './illustrator.service';
import { WorkManagerState } from '../pages/work/work-manager/work-manager.service';
export class LibraryElement
{
  document: MoonDeskDocument;
  version: DocumentVersion;

  // UI Only
  busy?: boolean;
  fileExtension?: string;
  rootFileName?: string;

  /**
   * 0 while download didn't start
   * 100 when ready
   * -1 when there was an error
   */
  progress: Progress;
  progressEvent: EventEmitter<LibraryElement> = new EventEmitter<LibraryElement>();
  cachedFilePath?: string;
  error?: any;
  promise?: Promise<void>;

  constructor(doc?: MoonDeskDocument, version?: DocumentVersion, libDocDto?: LibraryDocForExtensionDto)
  {
    if (doc && version)
    {
      this.document = doc;
      this.version = version;
    }
    else if (libDocDto)
    {
      this.document =
      {
        classValues: null,
        company: null,
        latestVersion: null,
        documentType:
        {
          classes: null,
          companyId: null,
          fieldTypes: null,
          name: libDocDto.type === LibraryTypeEnum.Font ? LibDocTypesNames.fontTypeName : LibDocTypesNames.imageTypeName
        },
        id: libDocDto.documentId,
        name: libDocDto.documentName,
        companyId: libDocDto.companyId,
        markedAsDeleted: libDocDto.isArchived,
        previewImageUrl: libDocDto.previewUrl ?? '../../assets/DefaultPreview.png'
      };
      this.version =
      {
        documentTags: libDocDto.tags,
        fieldValues: null,
        metadata: null,
        id: libDocDto.versionId,
        fileType: libDocDto.fileType,
        checksum: libDocDto.checksum,
        storageUsage: libDocDto.documentSize
      };
      this.document.latestVersion = this.version;
    }

    this.progress = {percentage: 0} as Progress;
  }
}

export interface LibraryResponse
{
  result: LibraryElement[];
  page?: number;
  pageCount?: number;
  pageSize?: number;
  totalResult?: number;
}

// If this changes, be sure to update it also in the backend (Backend.Core/Models/Constants.cs)
export const allowedFontExtensions: string[] =
[
  '.jfproj', '.fnt', '.woff', '.pfa', '.fot', '.ttf', '.sfd', '.vlw',
  '.otf', '.pfb', '.gxf', '.odttf', '.woff2', '.etx', '.fon', '.chr',
  '.ttc', '.vfb', '.bdf', '.pmt', '.gf', '.amfm', '.pfm', '.mf', '.abf',
  '.compositefont', '.gdr', '.vnf', '.mxf', '.pcf', '.xfn', '.bf', '.sfp',
  '.pf2', '.glif', '.tfm', '.pfr', '.afm', '.tte', '.dfont', '.xft',
  '.acfm', '.eot', '.pk', '.ffil', '.suit', '.nftr', '.txf', '.euf', '.mcf',
  '.cha', '.lwfn', '.ufo', '.t65', '.ytf', '.f3f', '.pft', '.fea', '.sft'
];

@Injectable({
  providedIn: 'root'
})
export class LibraryCacheService
{
  public updating: boolean = true;
  public error: any;
  public cacheChanged: EventEmitter<void> = new EventEmitter();

  private libraryElements: LibraryElement[] = [];
  private currentCompanyId: string = undefined;

  /**
   * A library element is for a certain version, so there can be
   * more than one element for one document.
   * For the list in the library component, we want to reduce this
   * to the elements with the latest version...
   */
  getLibraryList(): LibraryElement[]
  {
    const result: LibraryElement[] = [];
    for (let i = 0; i < this.libraryElements.length; i++)
    {
      const e = this.libraryElements[i];
      if (!_.any(result, r => r.document.id === e.document.id))
      {
        result.push(e);
      }
      else
      {
        const index = _.findIndex(result, r => r.document.id === e.document.id && r.version.versionNumber < e.version.versionNumber);
        if (index >= 0)
        {
          result.splice(index, 1, e);
        }
      }
    }
    return result;
  }

  constructor(private authService: AuthService,
              private documentService: DocumentService,
              private fileService: FilesystemService,
              private filePath: FilepathService,
              private logger: LoggingService,
              private illService: IllustratorService,
              private zone: NgZone)
  {
    this.init();
  }

  async init()
  {
    await this.authService.initPromise;
    this.authService.identityChanged.subscribe(() => this.updateLibrary());
    await this.updateLibrary();
  }

  async updateLibrary()
  {
    const identity = this.authService.getCurrentIdentity();
    this.error = undefined;
    const newCompanyId = identity?.company ? identity.company.id : undefined;
    if (this.updating && newCompanyId !== undefined && this.currentCompanyId === newCompanyId)
    {
      this.logger.trackTrace('Overlapping librarycache updates', Severity.Warning);
      return;
    }
    this.currentCompanyId = newCompanyId;
    try
    {
      this.updating = true;
      this.libraryElements = [];
      this.cacheChanged.emit();
      if (this.currentCompanyId !== undefined)
      {
        const filter: DocumentFilter = {companyId: this.currentCompanyId, library: true,
                                        classSelections: [], docTypeIds: [], page: 0, skipPreviewUrls: false};
        const response = await this.documentService.searchDocuments(filter);
        if (response.filter === filter && response && response.result)
        {
          const newLibElements = response.result.map<LibraryElement>(doc => new LibraryElement(doc, doc.latestVersion));
          // check if cached files exist, and update their path for each element
          this.checkLocalCopies(response.filter.companyId, newLibElements);
          // after checking all the files, let's make sure again that we're still with the correct company
          if (response.filter.companyId === this.currentCompanyId)
          {
            this.libraryElements = newLibElements;
          }
        }
        this.logger.trackTrace('Company changed while library was udpated, discarding results...', Severity.Information);
      }
    }
    catch (err)
    {
      this.logger.logException(err);
      this.error = err;
    }
    finally
    {
      this.updating = false;
      this.cacheChanged.emit();
    }
  }

  async searchLibraryElements(filter: DocumentFilter): Promise<LibraryResponse>
  {
    let result: LibraryResponse;

    const response = await this.documentService.searchLibraryDocsForExtension(filter);
    if (response && response.result)
    {
      const libElements = response.result.map<LibraryElement>(doc => new LibraryElement(null, null, doc));
      // check if cached files exist, and update their path for each element
      this.checkLocalCopies(filter.companyId, libElements);
      result =
      {
        result: libElements,
        page: response.page,
        pageCount: response.pageCount,
        pageSize: response.pageSize,
        totalResult: response.totalResult
      };
    }
    this.libraryElements = _.union(this.libraryElements, result.result);
    return result;
  }

  /**
   * Checks for the given elements if there are cached copies for it
   * If yes, an element will have progress=100 and the assigned cachedFilePath afterwards
   */
  private async checkLocalCopies(companyId: string, elements: LibraryElement[])
  {
    const cachedFiles = this.fileService.readdirSync(await this.getCompanyCacheFolder(companyId), true);

    for (let i = 0; i < elements.length; i++)
    {
      const libElement = elements[i];
      const fileName: string = this.getCacheFileName(libElement.version);
      const file = _.find(cachedFiles, cacheFile => this.fileService.parse(cacheFile).base === fileName);
      if (file)
      {
        const checksum = await this.fileService.fileChecksum(file);

        if (checksum !== libElement.version.checksum)
        {
          this.logger.trackTrace(`Cached file for library document ${libElement.document.id}
                                  , version ${libElement.version.id} has wrong checksum`);
        }
        else
        {
          if (libElement.progress)
          {
            libElement.progress.percentage = 100;
          }
          libElement.cachedFilePath = file;
        }
      }
    }
  }

  private async getCompanyCacheFolder(companyId: string): Promise<string>
  {
    const home = await this.filePath.getCacheFilePath();
    const result = await this.join(home, companyId);
    return result;
  }

  private getCacheFileName(version: DocumentVersion): string
  {
    return `${version.id}${version.fileType}`;
  }

  public async downloadDocument(libraryElement: LibraryElement, updateStatus?)
  {
    if (
      libraryElement.progress !== undefined &&
      libraryElement.progress.percentage > 0 &&
      libraryElement.progress.percentage < 100 &&
      libraryElement.promise)
    {
      await libraryElement.promise;
    }
    await this.checkLocalCopies(libraryElement.document.companyId, [libraryElement]);
    if (libraryElement.progress === undefined || libraryElement.progress.percentage !== 100)
    {
      libraryElement.promise = new Promise(async (res, rej) =>
      {
        const setProgress = (p: Progress) =>
        {
          this.zone.run(() =>
          {
            libraryElement.progress = p;
            libraryElement.progressEvent.emit(libraryElement);
          });
        };
        try
        {
          const fileContent = await this.documentService.downloadFile(libraryElement.version,
            progress =>
            {
              setProgress(progress);
              if (updateStatus)
              {
                updateStatus(WorkManagerState.loadingelement,
                  'Downloading linked document - ' + progress.loadedKB + ' kB / ' +
                    progress.totalKB + ' kB  (' + progress.speedKBps + ' kB/s)',
                  progress.percentage, null, progress.cancelAction);
              }
            });
          const fileName = this.getCacheFileName(libraryElement.version);
          let filePath = await this.getCompanyCacheFolder(libraryElement.document.companyId);
          filePath = await this.fileService.join(filePath, fileName);
          if (this.fileService.exists(filePath))
          {
            this.logger.trackTrace(`Overwriting file ${filePath}`, Severity.Warning);
          }
          await this.fileService.writeFileAsync(filePath, fileContent, {encoding: 'base64'});
          libraryElement.cachedFilePath = filePath;
          libraryElement.error = undefined;
        }
        catch (err)
        {
          libraryElement.progress.percentage = -1;
          libraryElement.error = err;
          libraryElement.progressEvent.emit(libraryElement);
        }
        if (libraryElement.progress.percentage === -1 || !libraryElement.cachedFilePath)
        {
          rej(libraryElement.error);
        }
        else
        {
          res();
        }
        libraryElement.promise = undefined;
      });
      await libraryElement.promise;
    }
  }

  public async copyToDownloadFolder(libraryElement: LibraryElement)
  {
    if (libraryElement.progress.percentage !== 100 || !libraryElement.cachedFilePath)
    {
      throw Error('Library element is not downloaded');
    }
    const downloadFolder = this.fileService.getDownloadsDirectory();
    const destinationPath = this.fileService.join(downloadFolder, libraryElement.document.name);
    await this.fileService.copyFile(libraryElement.cachedFilePath, destinationPath);
    this.fileService.open(destinationPath);
  }

  public async downloadLinkedFiles(docVersion: DocumentVersion, path: string, updateStatus?: () => Promise<void>, excludeFonts?: boolean)
  {
    await this.deleteOldLinkedFolder(path);

    const identity = this.authService.getCurrentIdentity();
    const childVersionIds = docVersion.childVersionIds;
    if (childVersionIds && childVersionIds.length > 0)
    {
      console.log(`Getting ${childVersionIds.length} libraryelements`);
      const childVersions = await this.getLibraryElementsByIds(childVersionIds, excludeFonts);
      for (let i = 0; i < childVersions.length; i++)
      {
        console.log(`Downloading library element ${childVersions[i].document.moonNumber}
                    , version ${childVersions[i].version.versionNumber}`);
        console.log(childVersions[i]);
        await this.downloadDocument(childVersions[i], updateStatus);
      }
      let linkFolder = this.fileService.parse(path).dir;
      linkFolder = await this.filePath.join(linkFolder, 'Links');
      for (let f = 0; f < childVersions.length; f++)
      {
        if (childVersions[f].document.documentType.name === LibDocTypesNames.imageTypeName)
        {
          const child = childVersions[f];
          console.log(`Copying ${child.cachedFilePath} to ${linkFolder}`);
          const parsedCacheFile = this.fileService.parse(child.cachedFilePath);
          const filename = parsedCacheFile.base;
          const target = this.fileService.join(linkFolder, filename);
          await this.fileService.copyFile(child.cachedFilePath, target);

          const startDate = new Date('2025-02-19T00:00:00Z').getTime();
          const endDate = new Date('2025-02-25T23:59:59Z').getTime();
          const versionTimestamp = new Date(docVersion.timestampUtc).getTime();

          if (child.document && child.document.name && versionTimestamp >= startDate && versionTimestamp <= endDate)
          {
            const originalFilename = child.document.name;
            const fixedTarget = this.fileService.join(linkFolder, originalFilename);
            await this.fileService.copyFile(child.cachedFilePath, fixedTarget);
          }

          // FIX for backup/restore...
          if (docVersion.restoreInformation?.linkReplacements && docVersion.restoreInformation.originalCompanyId !== identity.company.id)
          {
            const linkReplacement =
              _.find(docVersion.restoreInformation.linkReplacements, lr => lr.item2 === child.document.latestVersionId);
            if (linkReplacement)
            {
              // this document was restored from another workspace, so the .ai file still has references/links to another versionid
              // the best workaround is to create duplicates of the files in the 'Links' folder so the .ai file keeps working
              // then, when saving a new version of the document, we fix that...
              const duplicateFile = linkReplacement.item1 + parsedCacheFile.ext;
              const duplicateTarget = this.fileService.join(linkFolder, duplicateFile);
              await this.fileService.copyFile(child.cachedFilePath, duplicateTarget);
            }
          }
        }
      }
    }
  }

  private async deleteOldLinkedFolder(aiDocumentPath: string)
  {
    try
    {
      const base = this.fileService.parse(aiDocumentPath).dir;
      const linkFolder = await this.filePath.join(base, '_Links');
      if (this.fileService.exists(linkFolder))
      {
        const result = await this.fileService.deleteFolderRecursive(linkFolder);
        if (!result)
        {
          console.log(`Error deleting old _Links folder`);
        }
      }
    }
    catch (err)
    {
      console.log(`Unexpected error deleting old _Links folder`);
      console.log(err);
    }
  }

  private async join(dir: string, sub: string)
  {
    if (!dir)
    {
        throw Error('Directory name cant be empty');
    }
    if (!sub)
    {
        throw Error(`Subpath for '${dir}' can't be empty`);
    }
    if (!await this.fileService.exists(dir))
    {
        await this.fileService.mkdir(dir);
    }
    const path = this.fileService.join(dir, sub);
    if (!await this.fileService.exists(path))
    {
        await this.fileService.mkdir(path);
    }
    return path;
  }

  public async getLibraryElementsByIds(versionIds: string[], excludeFonts?: boolean): Promise<LibraryElement[]>
  {
    let result: LibraryElement[] = [];
    const uncachedVersionIds: string[] = [];
    versionIds.forEach(vId =>
    {
      const known = this.libraryElements.find(le => le.version.id === vId);
      if (known)
      {
        result.push(known);
      }
      else
      {
        uncachedVersionIds.push(vId);
      }
    });
    if (uncachedVersionIds.length > 0)
    {
      const uncachedVersions = await this.documentService.getDocumentsByVersion(uncachedVersionIds);
      const uncachedElements = uncachedVersions.map(d => new LibraryElement(d, d.latestVersion));
      uncachedElements.forEach(v =>
      {
        result.push(v);
        this.libraryElements.push(v);
      });
    }

    if (excludeFonts)
    {
      result = _.filter(result, ver =>
        ver.document.documentType.isLibraryType &&
        ver.document.documentType.name !== LibDocTypesNames.fontTypeName
      );
    }

    return result;
  }

  public async getLibraryElement(doc: MoonDeskDocument): Promise<LibraryElement>
  {
    const r = new LibraryElement(doc, doc.latestVersion);
    this.checkLocalCopies(doc.companyId, [r]);
    return r;
  }

  public async createLibraryElement(sourcePath: string, progressCallback: (progress: Progress) => void): Promise<LibraryElement>
  {
    const parsed = this.fileService.parse(sourcePath);
    parsed.ext = parsed.ext ? parsed.ext.toLowerCase() : undefined;

    const identity = this.authService.getCurrentIdentity();

    const ext = parsed.ext;
    let libDocTypeId: string;

    const fileSize = this.fileService.getFileSize(sourcePath);
    if (!fileSize || fileSize <= 0)
    {
      // It is probably a "PostScript" font. Deprecated and not supported by adobe as of January 2023
      // https://helpx.adobe.com/fonts/kb/postscript-type-1-fonts-end-of-support.html
      throw new Error('Files without size are not allowed');
    }
    if (!ext)
    {
      throw new Error('Files without extension are not allowed');
    }
    if (ext.toLocaleLowerCase() === '.t1')
    {
      throw new Error('Type 1 fonts are not supported');
    }

    if (allowedFontExtensions.includes(ext.toLowerCase()))
    {
      const fontDocType =
        _.find(identity.company.documentTypes, docType => docType.isLibraryType && docType.name === LibDocTypesNames.fontTypeName);

      if (!fontDocType)
      {
        throw new Error('Missing font library document type');
      }
      libDocTypeId = fontDocType.id;
    }

    else
    {
      const imgDocType =
        _.find(identity.company.documentTypes, docType => docType.isLibraryType && docType.name === LibDocTypesNames.imageTypeName);

      if (!imgDocType)
      {
        throw new Error('Missing image library document type');
      }
      libDocTypeId = imgDocType.id;
    }

    const libElement: LibraryElement =
    {
      document: undefined,
      version: undefined,
      progressEvent: new EventEmitter<LibraryElement>(),
      progress: undefined
    };
    const content: Buffer = await this.fileService.readRawFileAsync(sourcePath);
    const checksum = await this.fileService.fileChecksum(sourcePath);

    const equal = _.find(this.libraryElements, e => e.document.latestVersion.checksum === checksum);
    let existingButArchived: boolean;
    if (equal !== undefined && !equal.document.markedAsDeleted)
    {
      throw new Error(`Duplicate library element (${equal.document.moonNumber})`);
    }
    else if (equal && equal.document.markedAsDeleted)
    {
      existingButArchived = true;
    }

    const id = await this.documentService.getNewDocumentId(true);
    const document: MoonDeskDocument =
    {
      id: id,
      number: 0,
      company: {id: identity.company.id},
      companyId: identity.company.id,
      documentType: {id: libDocTypeId, isLibraryType: true, companyId: null, name: null, classes: [], fieldTypes: []},
      classValues: [],
      name: parsed.name + parsed.ext,
      latestVersion: {
        documentTags: [],
        fieldValues: [],
        fileType: parsed.ext,
        metadata: new DocumentVersionMetadata()
      }
    };
    document.latestVersion.checksum = checksum;
    const documentPost: DocumentPost =
    {
      document: document,
      isMayorVersion: true,
      markApproved: true,
      taskActions: [],
      uploadedFromWeb : false
    };
    let result: MoonDeskDocument;
    try
    {
      if (!existingButArchived)
      {
        const post: DocumentRawData =
        {
          fileType: parsed.ext,
          fileContent: <Buffer>content
        };
        result = await this.documentService.postDocumentRaw(documentPost, undefined, post, progress =>
        {
          this.logger.trackTrace(`Uploading ${parsed.name}: ${progress.percentage ? progress.percentage : 0}%`, Severity.Verbose);
          progressCallback(progress);
          libElement.progress = progress;
          libElement.progressEvent.emit();
        });
      }
      else
      {
        await this.documentService.restoreDocument(equal.document.id);
        result = await this.documentService.getDocument(equal.document.id);
      }

    }
    catch (err)
    {
      throw new Error('Unexpected error uploading font file');
    }
    libElement.document = result;
    libElement.version = result.latestVersion;
    const fileName = this.getCacheFileName(libElement.version);
    let filePath = await this.getCompanyCacheFolder(libElement.document.companyId);
    filePath = await this.fileService.join(filePath, fileName);
    await this.fileService.copyFile(sourcePath, filePath);
    libElement.cachedFilePath = filePath;
    libElement.progress.percentage = 100;
    libElement.progressEvent.emit();

    this.libraryElements.splice(0, 0, libElement);
    this.cacheChanged.emit();
    return libElement;
  }

  async archiveLibElement(libElement: LibraryElement)
  {
    await this.documentService.removeDocument(libElement.document.id);
    const index = this.libraryElements.indexOf(libElement);
    this.libraryElements.splice(index, 1);
    this.cacheChanged.emit();
  }

  async restoreLibElement(libElement: LibraryElement)
  {
    await this.documentService.restoreDocument(libElement.document.id);
    this.cacheChanged.emit();
  }

  async createPdf(documentPath: string): Promise<string|Buffer>
  {
    this.logger.trackTrace(`Generating preview pdf`);
    const pdfPath = await this.filePath.createPreviewPdfTempPath();
    await this.illService.saveAsPdf2(documentPath, pdfPath, true);

    const buffer = <Buffer>await this.fileService.readRawFileAsync(pdfPath);
    return buffer;
  }

  async cleanTempFolder(): Promise<boolean>
  {
    const tempFolderPath = await this.filePath.getTempFolderPath();
    if (this.fileService.exists(tempFolderPath))
    {
      return await this.fileService.deleteFolderRecursive(tempFolderPath);
    }
    return true;
  }
}
