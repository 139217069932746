import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import * as _ from 'underscore';
import {
  Identity,
  BackendInfo,
  FrontendInfo,
  EventHubService,
  SupportService,
  AuthService,
  PlatformInfoProvider,
  TranslationService
} from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { MatDialog } from '@angular/material/dialog';
import { AskDialogComponent } from '../_shared/ask-dialog/ask-dialog.component';

@Component({
    selector: 'sidenav-content',
    templateUrl: './sidenav-content.component.html',
    styleUrls: ['./sidenav-content.component.scss'],
    standalone: false
})
export class SidenavContentComponent implements OnInit {

  @Input() identityLoaded: boolean;
  @Input() identity: Identity;
  @Input() showIcons: boolean;
  @Input() showSettings: boolean;

  currentUrl: string;
  @Input() set url(url: string)
  {
    this.currentUrl = url;
  }
  @Input() menuItems: MenuItem[];

  @Output() toggleSideNav: EventEmitter<void> = new EventEmitter<void>();
  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() menuAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() settingsClicked: EventEmitter<void> = new EventEmitter<void>();

  showProfile: boolean;
  backendInfo: BackendInfo;
  frontendInfo: FrontendInfo;

  constructor(private eventHub: EventHubService,
    private supportService: SupportService, //must be injected, if not it doesn't get loaded at all
    private authService: AuthService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider
  )
  {
    platformInfoProvider.backendChanged.subscribe(() => this.updateInfo());
    this.authService.identityChanged.subscribe(() => this.updateInfo());
  }

  async ngOnInit() {
    await this.authService.waitForInit();
    this.updateInfo();
  }

  private async updateInfo()
  {
    this.backendInfo = await this.platformInfoProvider.getBackendInfo();
    this.frontendInfo = await this.platformInfoProvider.getFrontendInfo();
  }

  closeSideNav()
  {
    this.toggleSideNav.emit();
  }

  logout()
  {
    this.logoutEvent.emit();
  }

  toggleProfile()
  {
    this.showProfile = !this.showProfile;
  }

  actionClicked(option: MenuItem)
  {
    this.menuAction.emit(option.label);
  }

  settings()
  {
    this.settingsClicked.emit();
  }

  tutorial()
  {
    this.eventHub.openTutorial.emit();
  }

  openSupport()
  {
    this.closeSideNav();
    this.eventHub.openSupportClicked.emit();
  }

  async forceUpdate()
  {
    // ToDo - Check if there is actually a new version before asking for the update

    const title = this.translationService.getTranslation('lid.ext.pages.sidenav-content.updateTitle');
    const question = this.translationService.getTranslation('lid.ext.pages.sidenav-content.updateQuestion');
    const answer = await this.askDialog(title, question);
    if (answer)
    {
      window.location.reload();
    }
  }

  private askDialog(title: string, question: string): Promise<string>
  {
    return new Promise<string>((resolve) =>
    {
      const dialogRef = this.dialog.open(AskDialogComponent, {
        width: '400px',
        minWidth: '260px',
        data:
        {
          title: title,
          question: question
        }
      });
      dialogRef.afterClosed().subscribe(result => resolve(result));
    });
  }
}

export interface MenuItem
{
  label: string;
  url?: string;
  //active: boolean;
}
