import { Company } from '../configuration/Company';
import { User } from '../user/User';
import { ImageCompareResult } from './ImageCompareResult';
import { ImageOcrResult } from './ImageOcrResult';
import { ImageCompareConfig } from './ImageCompareConfig';
import { ImageCompareBarcodeResult } from './ImageCompareBarcodeResult';
import { DocumentVersion } from '../document/DocumentVersion';


export interface FileInfo
{
    // This name is always original.png or print.png to locate file in storage..
    name: string;
    // This must be used if you want to show the real file name of uploaded file.
    realFileName?: string;
    /**
     * File type with point
     * .jpg .png ...
     */
    filetype: string;
    width: number;
    height: number;
    horizontalResolution: number;
    verticalResolution: number;

    /**
     * Exist as reference when use a MoonDesk document , for now combination is MoonNumber + V + VersionNumber
     */
    referenceVersionName?: string;

    documentVersionId?: string;

    documentVersion?: DocumentVersion;
}

export interface ImageCompare
{
    id?: string;
    timestampUtc?: Date;
    number?: number;

    status?: 'Requested' | 'InProgress' | 'Failed' | 'Done' | 'Deleted' | 'Saved' | 'Starred';

    /**
     * In case of 'Failed'
     */
    error?: string;

    /**
     * Results from the compare algorithm
     * (json string with flexible details)
     */
    results?: string;

    resultFiles?: string[];

    userId?: string;
    user?: User;

    companyId?: string;
    company?: Company;

    name?: string;
    description?: string;
    scale?: 'small' | 'medium' | 'big';

    imageCompareConfig?: ImageCompareConfig;

    reviewStatus?: 'Pending' | 'Rejected' | 'Approved';

    algorithm?: AlignmentAlgorithm;

    /**
     * This is a bit messy, sorry
     * When imagecompare objects come from backend, they just have
     * this list of fileInfos. the frontend service looks for the
     * original and printFileInfo
     */
    inputFileInfos?: FileInfo[];
    /**
     * Assigned from ImageComparationService
     * - when a new IC is being sent to the backend (it's about the original dpi info)
     * - when IC's are read from the backend
     */
    originalFileInfo?: FileInfo;
    /**
     * Assigned from ImageComparationService
     * - when a new IC is being sent to the backend (it's about the original dpi info)
     * - when IC's are read from the backend
     */
    printFileInfo?: FileInfo;

    imageCompareBarCodeResult?: ImageCompareBarcodeResult;
    /**
     * Python compare results, one for each sigma that was executed
     */
    imageCompareResults: ImageCompareResult[];

    /**
     * Text recognition results, one for each selected configuration
     */
    imageOcrResults: ImageOcrResult[];

    /**
     * The time the python function needed for alignment
     */
    alignmentTimeMs?: number;
    /**
     * The total time for creating the intial comparison
     * in MoonDesk, including the Python processing time.
     */
    totalCreationTimeMs?: number;

    /**
     * Tags for image compares classification
     */
    tags?: ImageCompareTag[];
}

export interface ImageCompareTag
{
    id?: string;
    value: string;
}

export enum AlignmentAlgorithm
{
    SIFT = 'sift',
    ORB = 'orb',
    AKAZE = 'akaze'
}
