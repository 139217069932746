import { Injectable, Inject } from '@angular/core';
import * as _ from 'underscore';
import { AuthService } from './auth.service';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { Identity } from '../_models/user/Identity';
import { User } from '../_models/user/User';
import { CookieService } from 'ngx-cookie-service';
import { BackendInfo } from '../_models/api/BackendInfo';
import { EventHubService } from './event-hub.service';
import { Subscription } from 'rxjs';
import { AppInsightsService } from './app-insights.service';

export class HubspotLogin
{
  user: User;
  companyName: string;
}

export enum Severity
{
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export interface TrackTraceEvent
{
  message: string,
  severity: Severity
}

export interface GoogleEvent
{
  /**
   * It must be one of the events listed here:
   * https://ygdesignsa-my.sharepoint.com/:x:/g/personal/rperez_creative4impact_com/ETjCPKrYKixAgAEOi0EZfpMBKUMRO5tLSi1AF9xe21ty_A?rtime=F9ua4Dfq2Ug
   */
  eventName: string;
  /**
   * Main MoonDesk modules
   */
  section: 'dashboard' | 'menu' | 'tasks' | 'documents' | 'rules' | 'shared' | 'review' | 'products' | 'reports' | 'compare';
  /**
   * list, filter, edit, create, review, etc
   */
  subsection?: string;
  /**
   * Content related to the event: search by <content>, order by <content>, etc
   */
  content?: string;
}

interface HubspotResponse
{
  results: string[];
}

interface HubspotField
{
  name: string;
  value: string;
}

const hubspotUrl: string = 'https://api.hsforms.com/submissions/v3/integration/submit';
const hubSpotPortalId: string = '6414078';
const hubSpotFormIdLogin: string = 'edca3f06-94e9-43a5-bb41-c39be2991dd3';
const hubSpotFormIdRequestImageCompare: string = '0618b753-3b8d-4597-a627-79fcfa8215e8';
const hubSpotFormIdCreateTask: string = 'b77d41d3-1b55-487c-9d25-889d0e510a4c';
const hubSpotFormIdReviewTask: string = 'f494623b-87e4-477c-883c-a6273dd70768';
const hubSpotFormIdRegister: string = '30c729e6-25f6-4e59-bbc0-0c826f123da3';
const hubSpotFormIdDownloadFile: string = 'e2254f13-72bd-4489-bf92-0bfefd65baf0';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class LoggingService
{

  constructor(private authService: AuthService,
              @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
              private cookieService: CookieService,
              private eventHubService: EventHubService,
              private appInsightsService: AppInsightsService)
  {
    platformInfoProvider.backendChanged.subscribe(() => this.updateTargetsPromise = this.updateTargets());
    authService.identityChanged.subscribe(() => this.updateTargetsPromise = this.updateTargets());
    this.updateTargetsPromise = this.updateTargets();
    authService.logHubspotSignInEvent.subscribe(loginData => this.logHubspot_Login(loginData));
    eventHubService.extendedScriptLoggging.subscribe(extendedScriptEvent =>
      this.trackTrace(extendedScriptEvent.message, extendedScriptEvent.severity));
  }

  private updateTargetsPromise: Promise<void>;
  private backendInfo: BackendInfo;

  private googleTrackingId: string = undefined;
  private googleTagManagerId: string = undefined;

  private appInsightsInitialized: boolean;
  private identityChangeSubscription: Subscription;

  private async updateTargets()
  {
    try
    {
      await this.authService.initPromise;
      this.backendInfo = await this.platformInfoProvider.getBackendInfo();
      await this.initAppInisght();
      const frontend = this.authService.frontendInfo.frontendName;
      const trackId = frontend === 'WEB' ? this.backendInfo.googleTrackingId : this.backendInfo.googleTrackingIdExtension;
      const tagMgrId = frontend === 'WEB' ? this.backendInfo.googleTagManagerId : undefined;
      if (trackId !== this.googleTrackingId)
      {
        this.updateGTag(trackId);
        this.setGoogleUserProperties();
      }
      if (tagMgrId !== this.googleTagManagerId)
      {
        this.updateTagManager(tagMgrId);
      }
    }
    catch (err)
    {
      console.log('ERROR UPDATING LOGGING TARGETS');
      console.log(err);
    }
  }

  private async initAppInisght()
  {
    this.appInsightsInitialized = await this.appInsightsService.initAppInsights(this.backendInfo.appInsightsKey);
    if (!this.identityChangeSubscription)
    {
      this.identityChangeSubscription = this.authService.identityChanged.subscribe(a => this.updateIdentity(a));
    }
    await this.updateIdentity(this.authService.getCurrentIdentity());
  }

  private async updateIdentity(identity: Identity)
  {
    if (this.appInsightsInitialized)
    {
      this.appInsightsService.properties = await this.platformInfoProvider.getLogProperties();

      let authenticated: string;
      if (identity && identity.user)
      {
        authenticated = identity.user.username;
        this.appInsightsService.properties['username'] = authenticated;
      }
      else if (identity && identity.reviewer)
      {
        authenticated = identity.reviewer.email;
        this.appInsightsService.properties['username'] = authenticated;
      }

      const company = identity && identity.company ? identity.company.name : undefined;
      this.appInsightsService.properties['company'] = company;

      this.appInsightsService.refreshAuthentication(authenticated, company);
    }
  }

  /**
   * Add gtag.js (Google Tag)
   */
  private updateGTag(googleTrackingId: string)
  {
    try
    {
      const gtagscript = <HTMLScriptElement>document.getElementById('gtagloader');
      if (gtagscript)
      {
        document.removeChild(gtagscript);
      }
      const initscript = <HTMLScriptElement>document.getElementById('gtaginit');
      if (initscript)
      {
        document.removeChild(initscript);
      }
    }
    catch (err)
    {
      console.log('ERROR REMOVING GTAG');
      console.log(err);
    }
    try
    {
      this.googleTrackingId = googleTrackingId;
      if (this.googleTrackingId)
      {
        const src = `https://www.googletagmanager.com/gtag/js?${googleTrackingId}`;
        const gtagscript = document.createElement('script');
        gtagscript.id = 'gtagloader';
        gtagscript.type = 'text/javascript';
        gtagscript.src = src;
        gtagscript.async = true;
        const b = document.getElementsByTagName('body')[0];
        b.appendChild(gtagscript);

        const initscript = document.createElement('script');
        initscript.id = 'gtaginit';
        initscript.innerText = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleTrackingId}');`;
        b.appendChild(initscript);
      }
    }
    catch (err)
    {
      console.log('ERROR CREATING GTAG');
      console.log(err);
    }
  }

  private setGoogleUserProperties()
  {
    const identity = this.authService.getCurrentIdentity();
    gtag('set', 'user_properties', {
      'username': identity.user ? identity.user.username : null,
      'reviewer_email': identity.reviewer ? identity.reviewer.email : null,
      'workspace': identity.company ? identity.company.name : null
    });
  }

  private updateTagManager(googleTagManagerId: string)
  {
    try
    {
      const tagMgrScript = <HTMLScriptElement>document.getElementById('tagmgrscript');
      if (tagMgrScript)
      {
        document.removeChild(tagMgrScript);
      }
      const tagMgrNoScript = <HTMLScriptElement>document.getElementById('tagmgrnoscript');
      if (tagMgrNoScript)
      {
        document.removeChild(tagMgrNoScript);
      }
    }
    catch (err)
    {
      console.log('ERROR REMOVING TAG MANAGER');
      console.log(err);
    }
    try
    {
      this.googleTagManagerId = googleTagManagerId;
      if (googleTagManagerId)
      {
        const head = document.getElementsByTagName('head')[0];
        const body = document.getElementsByTagName('body')[0];

        const script = document.createElement('script');
        script.id = 'tagmgrscript';
        script.innerText = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${googleTagManagerId}');
                    `;
        head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.id = 'tagmgrnoscript';
        noscript.innerText = `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
                              height="0" width="0" style="display:none;visibility:hidden"></iframe>
                             `;
        body.appendChild(noscript);
      }
    }
    catch (err)
    {
      console.log('ERROR UPDATING TAG MANAGER');
      console.log(err);
    }
  }

  trackTrace(message: string, severity: Severity = Severity.Verbose)
  {
    this.appInsightsService.trackTrace(message, severity);
  }

  trackEvent(message: string, measurements?: { [name: string]: number }, properties?: { [name: string]: string })
  {
    this.appInsightsService.trackEvent(message, measurements, properties);
  }

  trackGoogleEvent(event: GoogleEvent)
  {
    try
    {
      if (this.googleTrackingId)
      {
        gtag('event', event.eventName,
        {
          'section': event.section,
          'subsection': event.subsection,
          'content': event.content
        });
      }
    }
    catch (err)
    {
      console.log(err);
    }
  }

  startTrackEvent(event: string)
  {
    this.appInsightsService.startTrackEvent(event);
  }

  stopTrackEvent(event: string, measurements?: { [name: string]: number }, properties?: { [name: string]: string })
  {
    this.appInsightsService.stopTrackEvent(event, measurements, properties);
  }

  trackPageView(pageName: string, measurements?: { [name: string]: number })
  {
    const id = this.authService.getCurrentIdentity();
    this.appInsightsService.trackPageView(pageName, measurements, id !== undefined && id.user !== undefined);
    if (this.googleTrackingId)
    {
      gtag('config', this.googleTrackingId, {'page_path': pageName } );
    }
  }

  logException(error: Error, measurements?: { [name: string]: number }, message?: string)
  {
    this.appInsightsService.logException(error, measurements);
    if (this.googleTrackingId)
    {
      gtag('event', 'exception', {
        'description': message,
        'fatal': false
      });
    }
  }

  logHubspot_Login( data: HubspotLogin)
  {
    const fields: HubspotField[] = [
      {name: 'email', value: data.user.email},
      {name: 'space' , value: data.companyName}
    ];
    this.logHubspotForm('Login', hubSpotFormIdLogin, fields);
  }

  logHubspot_RequestImageCompare()
  {
    const id = this.authService.getCurrentIdentity();
    const fields: HubspotField[] = [
      {name: 'email', value: id.user.email},
      {name: 'space', value: id.company.name}
    ];
    this.logHubspotForm('RequestImageCompare', hubSpotFormIdRequestImageCompare, fields);
  }

  logHubspot_CreateTask()
  {
    const id = this.authService.getCurrentIdentity();
    const fields: HubspotField[] = [
      {name: 'email', value: id.user.email},
      {name: 'space', value: id.company.name}
    ];
    this.logHubspotForm('CreateTask', hubSpotFormIdCreateTask, fields);
  }

  logHubspot_ReviewTask()
  {
    const id = this.authService.getCurrentIdentity();
    const email = id.user ? id.user.email : id.reviewer.email;
    const space = id.company.name;
    const fields: HubspotField[] = [
      {name: 'email', value: email},
      {name: 'space', value: space}
    ];
    this.logHubspotForm('ReviewTask', hubSpotFormIdReviewTask, fields);
  }

  logHubspot_Register()
  {
    const id = this.authService.getCurrentIdentity();
    const fields: HubspotField[] = [
      {name: 'email', value: id.user.email},
      {name: 'space', value: id.company ? id.company.name : 'Not created yet'}
    ];
    this.logHubspotForm('Register', hubSpotFormIdRegister, fields);
  }

  logHubspot_DownloadDocument()
  {
    const id = this.authService.getCurrentIdentity();
    const fields: HubspotField[] = [
      {name: 'email', value: id.reviewer.email},
      {name: 'space', value: id.company ? id.company.name : 'Not created yet'}
    ];
    this.logHubspotForm('DownloadFile', hubSpotFormIdDownloadFile, fields);
  }

  async logHubspotForm(pageName: string, formId: string, fields: HubspotField[])
  {
    try
    {
      await this.updateTargetsPromise;
      if (this.authService.frontendInfo.frontendName !== 'WEB')
      {
        console.log('HUBSPOT logs only for WEB');
        return;
      }
      if (this.backendInfo.logHubspotForms !== true)
      {
        console.log('HUBSPOT logs deactivated');
        return;
      }
      const hutk = this.cookieService.get('hubspotutk');
      const data = {
        fields: fields,
        context: {
          hutk: hutk,
          pageUri: this.backendInfo.ipAddress,
          pageName: pageName
        }
      };
      console.log(`HUBSPOT Sending form ${pageName}`);
      console.log(data);
      const url = `${hubspotUrl}/${hubSpotPortalId}/${formId}`;
      this.authService.connectionService.httpClient.post<HubspotResponse>(url, data)
      .subscribe( dt =>
      {
        console.log(`HUBSPOT form ${pageName} sent`);
        console.log(dt);
      },
      error =>
      {
        console.log(`HUBSPOT Error sending form ${pageName}`);
        this.logException(error);
      });
    }
    catch (err)
    {
      console.log(`HUBSPOT Unexpected error sending form ${pageName}`);
      this.logException(err);
    }
  }
}
